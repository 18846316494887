import usePricing from '../api/usePricing'

import PricingContext from './PricingContext'

interface PricingProviderProps {
  children: React.ReactNode
  subscribedPlansOnly?: boolean
}

export default function PricingProvider({
  children,
  subscribedPlansOnly,
}: PricingProviderProps): JSX.Element {
  const pricingPlans = usePricing({subscribedPlansOnly})

  return <PricingContext.Provider value={pricingPlans}>{children}</PricingContext.Provider>
}
