import {css} from '@emotion/react'
import {Button} from '@kensho/ui'
import {useNavigate} from 'react-router-dom'

// Override the inner span to make the whole button a link
const buttonCss = css`
  position: relative;
  width: 90px;

  span {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`

const signInCss = css`
  line-height: 38px;
  color: #09819f;
`

export default function SignInButton({
  tabIndex,
  signInURL = '/users/login',
}: {
  tabIndex?: number
  signInURL?: string
}): JSX.Element {
  const navigate = useNavigate()

  return (
    <Button
      size="large"
      css={buttonCss}
      minimal
      onClick={() => navigate(signInURL)}
      tabIndex={tabIndex || 0}
    >
      <a href={signInURL} css={signInCss} tabIndex={-1}>
        Sign In
      </a>
    </Button>
  )
}
