import fetchResource from '../utils/fetchResource'

export interface ProductMetadataResponse {
  product: {
    stripeProductId: string
    name: string
    description: string
  }
  documentationLink?: string
  unitType: string
  unitDescription: string
  usageExternalLinkTitle: string
  unitUsageDescription: string
}

/**
 * Fetch the metadata options for all products or a specific product
 *
 * @param stripePriceId The Stripe price id of the product for which we want metadata.
 */
export default function fetchProductMetadata(
  stripePriceId?: string,
): Promise<ProductMetadataResponse[]> {
  return fetchResource<ProductMetadataResponse[]>(
    `/api/v1/product_metadata${stripePriceId ? `?stripe_price_id=${stripePriceId}` : ''}`,
  )
}
