export const BREAKPOINTS = {
  LARGE: '1800px',
  MEDIUM: '1200px',
  SMALL: '700px',
  XSMALL: '400px',
}

export const STANDARD_BREAKPOINTS = {
  WIDE: '2000px',
  DESKTOP: '1252px',
  TABLET: '524px',
  MOBILE: '375px',
}
