import {css} from '@emotion/react'

import {productCardInfo} from '../../constants/productCardInformation'
import isSPEmail from '../../utils/isSPEmail'

const errorSectionCss = css`
  margin-top: 12px;
  color: #db374d;
  border: 2px solid #db374d;
  background-color: rgba(219, 55, 77, 0.12);
  text-align: center;
`

const errorMessageCss = css`
  color: #db374d;
`

const errorMessageLinkCss = css`
  ${errorMessageCss}
  text-decoration: underline;
`

interface SubmissionErrorMessageProps {
  email: string | null
  defaultAPIError: boolean
  product: string | null
}

export default function SubmissionErrorMessage({
  email,
  product,
  defaultAPIError,
}: SubmissionErrorMessageProps): JSX.Element {
  if (email && isSPEmail(email))
    return (
      <section css={errorSectionCss}>
        {defaultAPIError ? (
          <>
            Please log in{' '}
            <a css={errorMessageLinkCss} href="http://kensho.okta.com/ ">
              here
            </a>{' '}
            to access Kensho&apos;s AI Toolkit.
          </>
        ) : (
          'Accounts cannot be created for this email domain.'
        )}
      </section>
    )
  return (
    <section css={errorSectionCss} data-testid="submission-success-indicator">
      {defaultAPIError ? (
        <>
          We cannot create an account for you at this time. If you already have an account, please{' '}
          <a
            css={errorMessageLinkCss}
            href={
              product && product in productCardInfo
                ? productCardInfo[product].signInLink
                : 'https://services.kensho.com/users/login'
            }
          >
            log in here
          </a>
          .
        </>
      ) : (
        'Accounts cannot be created for this email domain.'
      )}
    </section>
  )
}
