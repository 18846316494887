interface ErrorResponse<T> {
  status: number
  error: T
}

interface SuccessResponse<T> {
  status: number
  value: T
}

export default async function postData<ValueShape, ErrorShape>(
  url = '',
  data = {},
): Promise<SuccessResponse<ValueShape> | ErrorResponse<ErrorShape>> {
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  })
  if (!response.ok) {
    return {status: response.status, error: await response.json()}
  }
  return {status: response.status, value: await response.json()} // parses JSON response into native JavaScript objects
}
