import {useRef, useEffect, useState} from 'react'
import {css} from '@emotion/react'
import {Button} from '@kensho/ui'
import {IconEye} from '@kensho/icons'

import {VALIDATION_ERROR} from './constants'

const labelCss = css`
  display: block;
  font-weight: bold;
  line-height: 18px;
  margin: 0;
  font-size: 14px;
  margin: 24px 0 0;
`

const inputErrorCss = css`
  border: 2px solid #db374d;
  margin-bottom: 0px;
`

const inputContainerCss = css`
  display: flex;
  margin-top: 6px;
  height: 36px;
`

const inputCss = css`
  flex: 1;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-right: none;
  border-radius: 0;
  padding: 4px 8px;
  max-width: 100%;
  display: block;
  font-weight: normal;

  &:invalid {
    border: 2px solid red;
  }

  &:focus {
    border: 2px solid rgba(9, 129, 159, 0.5);
  }

  &:hover:not(:focus):not(${inputErrorCss}) {
    border-color: rgba(0, 0, 0, 0.54);
  }
`

const visibilityButtonCss = css`
  padding: 0;
  width: 36px;
  height: 36px;

  svg {
    margin: 0;
  }
`

const passwordValidationCss = css`
  line-height: 16px;
  font-size: 12px;
  margin: 0;
  text-align: start;
`

const errorCss = css`
  color: #db374d;
`

interface PasswordInputProps extends React.HTMLAttributes<HTMLInputElement> {
  value: string
  autofocus?: boolean
  label: string
  onChange: React.ChangeEventHandler<HTMLInputElement>
  id: string
  placeholder?: string
  errors: string[]
}

function ErrorText(props: {children: React.ReactNode; hasError: boolean}): JSX.Element {
  const {children, hasError} = props
  return (
    <span data-testid="password-error-text" css={hasError && errorCss}>
      {children}
    </span>
  )
}

export default function PasswordInput({
  value,
  autofocus = false,
  label,
  onChange,
  id,
  placeholder,
  errors = [],
}: PasswordInputProps): JSX.Element {
  const [passwordVisible, setPasswordVisible] = useState(false)

  const ref = useRef<HTMLInputElement>(null)

  const hasError = errors.length > 0
  const lengthError = hasError && errors.includes(VALIDATION_ERROR.TOO_SHORT)
  const lowercaseError = hasError && errors.includes(VALIDATION_ERROR.LOWERCASE)
  const uppercaseError = hasError && errors.includes(VALIDATION_ERROR.UPPERCASE)
  const symbolError = hasError && errors.includes(VALIDATION_ERROR.SYMBOL)
  const numberError = hasError && errors.includes(VALIDATION_ERROR.NUMBER)

  useEffect(() => {
    if (autofocus && ref.current) ref.current.focus()
  }, [ref, autofocus])

  return (
    <>
      <label css={labelCss} htmlFor={id}>
        {label}
      </label>
      <div css={inputContainerCss}>
        <input
          maxLength={72}
          id={id}
          ref={ref}
          css={inputCss}
          name={id}
          type={!passwordVisible ? 'password' : 'text'}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
        <Button
          css={visibilityButtonCss}
          icon={IconEye}
          intent={passwordVisible ? 'primary' : undefined}
          onClick={() => setPasswordVisible(!passwordVisible)}
        />
      </div>
      <p css={passwordValidationCss}>
        At least <ErrorText hasError={lengthError}>10 characters</ErrorText>, using a mix of{' '}
        <ErrorText hasError={lowercaseError}>lowercase letters</ErrorText>,{' '}
        <ErrorText hasError={uppercaseError}>uppercase letters</ErrorText>,{' '}
        <ErrorText hasError={symbolError}>symbols</ErrorText>, and{' '}
        <ErrorText hasError={numberError}>numbers</ErrorText>.
      </p>
    </>
  )
}
