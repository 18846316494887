export const VALIDATION_ERROR = {
  EMPTY: 'EMPTY',
  TOO_SHORT: 'TOO_SHORT',
  TOO_LONG: 'TOO_LONG',
  LOWERCASE: 'LOWERCASE',
  UPPERCASE: 'UPPERCASE',
  SYMBOL: 'SYMBOL',
  NUMBER: 'NUMBER',
}

export function getErrorMessage(error: string): string {
  switch (error) {
    case VALIDATION_ERROR.EMPTY:
      return 'This field is required'
    case VALIDATION_ERROR.TOO_SHORT:
      return 'Must be at least 10 characters'
    case VALIDATION_ERROR.TOO_LONG:
      return 'Must be less than 72 characters'
    case VALIDATION_ERROR.LOWERCASE:
      return 'Must contain a lowercase letter'
    case VALIDATION_ERROR.UPPERCASE:
      return 'Must contain an uppercase letter'
    case VALIDATION_ERROR.SYMBOL:
      return 'Must contain a symbol'
    default:
      return ''
  }
}
