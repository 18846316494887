import {css} from '@emotion/react'

import {BREAKPOINTS} from '../styleConstants'

const footerCss = css`
  display: flex;
  width: 100%;
  max-width: ${BREAKPOINTS.LARGE};
  justify-content: space-between;
  background-color: #f8f8f8;
  align-items: center;

  @media screen and (max-width: ${BREAKPOINTS.SMALL}) {
    flex-direction: column;
    gap: 20px;
  }
`

const contactUs = css`
  display: flex;
  gap: 7px;
  margin: 0;
`

const linksCss = css`
  display: flex;
  gap: 20px;
`

export default function Footer(): JSX.Element {
  return (
    <footer css={footerCss}>
      <p css={contactUs}>
        Have questions?{' '}
        <a href="https://kensho.com/contact/solutions" target="_blank" rel="noopener noreferrer">
          Contact the Kensho Team
        </a>
      </p>
      <div css={linksCss}>
        <a href="https://kensho.com/terms-of-use" target="_blank" rel="noopener noreferrer">
          Web Terms
        </a>
        <a href="https://kensho.com/terms-of-service" target="_blank" rel="noopener noreferrer">
          Service Terms
        </a>
        <a href="https://kensho.com/privacy" target="_blank" rel="noopener noreferrer">
          Privacy
        </a>
      </div>
    </footer>
  )
}
