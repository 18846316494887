import {useParams} from 'react-router-dom'

import {productCardList as rawProductCardList} from '../constants/productCardInformation'

import SolutionsPage from './LandingPage'

const productCardList = [...rawProductCardList]

export default function Solutions(): JSX.Element {
  const {product: productParam} = useParams()
  if (productParam) {
    const productIndex = productCardList.findIndex((product) => product.id === productParam)
    const productHead = productCardList.splice(productIndex, 1)
    productCardList.unshift(productHead[0])
  }

  const headerTitleText = productParam
    ? `Welcome to Kensho ${productCardList[0].productName} and Kensho's AI Toolkit!`
    : `Welcome to Kensho's AI Toolkit!`

  const headerSubtitleText = productParam
    ? `Sign up now for access to a free trial of Kensho ${productCardList[0].productName}, along with several other solutions in Kensho's AI Toolkit.`
    : `Use your free trial of Kensho's AI Toolkit to start uncovering insights in your data!`

  const taglineText = 'AI TOOLKIT'

  return (
    <SolutionsPage
      showFreeTrialLink
      taglineText={taglineText}
      headerTitleText={headerTitleText}
      headerSubtitleText={headerSubtitleText}
      productCardList={productCardList}
    />
  )
}
