import {Global, css} from '@emotion/react'

import './fonts/ProximaNova.css'

const globalCss = css`
  html {
    font-family: proxima-nova, 'Proxima Nova', sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }

  body {
    height: 100%;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }

  button,
  input,
  textarea,
  select {
    font: inherit;
  }

  a {
    text-decoration: none;
    color: #09819f;
  }

  button {
    border: none;
    cursor: pointer;
  }
`

export default function GlobalStyles(): JSX.Element {
  return <Global styles={globalCss} />
}
