import {useContext, useState, useCallback} from 'react'
import {css} from '@emotion/react'

import UserContext from '../providers/UserContext'
import useUser from '../api/useUser'

import Popover from './Popover'
import SpinnerIcon from './SpinnerIcon'
import Button from './Button/Button'
import ButtonLink from './Button/ButtonLink'

const userInfoCss = css`
  color: white;
  position: relative;
  z-index: 2;
`

const buttonCss = css`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: #00b9e8;
  cursor: pointer;
  outline: none;
  font-size: 16px;
  padding: 0;
`

const listCss = css`
  list-style-type: none;
  margin: 0;
  padding: 0;
`

const listItemCss = css`
  display: flex;
`

const logOutCss = css`
  flex: 1 1 0%;
  text-decoration: none;
  background: white;
  outline: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  padding: 0 3px;

  &:hover {
    background: #e4e4ed;
  }
`

const linkCss = css`
  color: #00b9e8;
  outline: none;
  text-decoration: none;
  font-size: 16px;
  white-space: nowrap;
  padding: 10px;
`

const loadingContainerCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
`

export default function UserInfo(): JSX.Element | null {
  const userData = useUser()
  const {logout} = useContext(UserContext)
  const [menuOpen, setMenuOpen] = useState(false)
  const openMenu = useCallback(() => setMenuOpen(true), [])
  const closeMenu = useCallback(() => setMenuOpen(false), [])

  // wait for user state to be known before rendering anything
  if (userData.status === 'pending') {
    return (
      <div css={loadingContainerCss}>
        <SpinnerIcon />
      </div>
    )
  }

  if (userData.status === 'error' || !userData.value.loggedIn) {
    return (
      <ButtonLink to="/users/login" css={linkCss} variant="minimal">
        Sign In
      </ButtonLink>
    )
  }

  return (
    <Popover
      isOpen={menuOpen}
      target={
        <div css={userInfoCss}>
          <Button variant="minimal" css={buttonCss} onClick={openMenu}>
            {userData.value.email}
          </Button>
        </div>
      }
    >
      <ul css={listCss}>
        <li css={listItemCss}>
          <Button onClick={logout} css={logOutCss} variant="minimal" onBlur={closeMenu} autoFocus>
            Log Out
          </Button>
        </li>
      </ul>
    </Popover>
  )
}
