/* eslint-disable jsx-a11y/tabindex-no-positive */
import {useState, useCallback, useContext} from 'react'
import {useNavigate, useLocation, Location, useParams} from 'react-router-dom'
import {css} from '@emotion/react'

import Button from '../../components/Button/Button'
import isEmailValid from '../../utils/isEmailValid'
import LabeledInput from '../../components/LabeledInput'
import {ROUTES} from '../../routes'
import getEmailFromURLParams from '../../utils/getEmailFromURLParams'
import {STANDARD_BREAKPOINTS} from '../../styleConstants'
import {productCardInfo} from '../../constants/productCardInformation'
import fetchResource from '../../utils/fetchResource'
import SpinnerIcon from '../../components/SpinnerIcon'
import isSPEmail from '../../utils/isSPEmail'
import SignUpModeContext from '../../providers/SignUpModeContext'

import ProductSignup from './ProductSignup'

const hiddenInputCss = css`
  visibility: hidden;
`

const continueButtonCss = css`
  margin: 10px;
  margin-top: 20px;
  padding: 6.5px 14px;
  color: #ffffff;
  font-size: 16px;
  white-space: nowrap;
  border: none;
  &:hover {
    opacity: 0.75;
  }

  @media screen and (max-width: ${STANDARD_BREAKPOINTS.TABLET}) {
    width: 100%;
    margin: 0;
    flex: 0 0 34px;
  }
`

const horizontalSignupCss = css`
  display: flex;
  align-items: center;
  position: relative;
  height: 122px;

  @media screen and (max-width: ${STANDARD_BREAKPOINTS.TABLET}) {
    position: static;
    flex-direction: column;
    align-items: start;
  }
`

const formCss = css`
  @media screen and (max-width: ${STANDARD_BREAKPOINTS.TABLET}) {
    display: flex;
    width: 100%;
    flex-grow: 1;
    flex-direction: column;
  }
`

const darkEmailErrorLabelCss = css`
  font-size: 12px;
  color: #ff0022;
  font-weight: 700;
  width: 320px;

  @media screen and (max-width: ${STANDARD_BREAKPOINTS.TABLET}) {
    width: 100%;
    padding-top: 20px;
  }
`

const darkLinkCss = css`
  text-decoration: underline;
  font-size: 12px;
  color: #ff0022;
  font-weight: 700;
`

const VALIDATION_ERRORS: {[key: string]: string} = {
  EMPTY: 'Email is required',
  INVALID: 'Invalid email. Please try again.',
  NONE: '',
}

const getInitialError = (location: Location): string => {
  const error = new URLSearchParams(location.search).get('error')
  if (!error) return VALIDATION_ERRORS.NONE
  return VALIDATION_ERRORS[error]
}

export default function Email(): JSX.Element {
  const location = useLocation()
  const {product: productParam} = useParams()
  const navigate = useNavigate()
  const signUpMode = useContext(SignUpModeContext)

  const [emailState, setEmailState] = useState({
    email: getEmailFromURLParams(location) || '',
    error: getInitialError(location),
  })
  const [loadingEmailAllowed, setLoadingEmailAllowed] = useState(false)
  const [domainIsDisallowed, setDomainIsDisallowed] = useState(false)

  const onEmailChange = useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
    const {value} = event.target

    setEmailState({email: value, error: VALIDATION_ERRORS.NONE})
  }, [])

  const submit = useCallback(() => {
    const valid = isEmailValid(emailState.email)
    if (valid) {
      if (isSPEmail(emailState.email)) {
        window.location.replace(
          productParam
            ? productCardInfo[productParam].signInLink
            : 'https://services.kensho.com/users/login',
        )
        return
      }

      fetchResource(`/users/v1/is_allowed_domain/?email=${emailState.email}`)
        .then(() => {
          const URLParams = new URLSearchParams(location.search)
          URLParams.set('email', encodeURIComponent(emailState.email))
          URLParams.delete('error')

          // set the user's email on the current route so it's present when they navigate back
          navigate(`${location.pathname}?${URLParams.toString()}`, {
            replace: true,
          })
          if (productParam) {
            URLParams.set('product', productParam)
          }
          const nextParams = `?${URLParams.toString()}`
          const nextUrl =
            signUpMode === 'generic' ? ROUTES.GENERIC_PASSWORD : ROUTES.FREE_TRIAL_PASSWORD

          // navigate to the next page
          navigate(`${nextUrl}${nextParams}`)
        })
        .catch((e) => {
          setLoadingEmailAllowed(false)
          if (e.status === 403) {
            setDomainIsDisallowed(true)
          }
        })
      setLoadingEmailAllowed(true)
    } else {
      setEmailState({email: emailState.email, error: VALIDATION_ERRORS.INVALID})
    }
  }, [emailState.email, navigate, location.search, productParam, location.pathname, signUpMode])

  const onFormSubmit = useCallback(
    (e: React.ChangeEvent<HTMLFormElement>) => {
      e.preventDefault()
      submit()
    },
    [submit],
  )

  const onEmailBlur = useCallback(() => {
    setEmailState(({email}) => ({
      email,
      error: isEmailValid(email) ? VALIDATION_ERRORS.NONE : VALIDATION_ERRORS.INVALID,
    }))
  }, [])

  return (
    <ProductSignup product={productParam ? productCardInfo[productParam] : undefined}>
      <div css={horizontalSignupCss}>
        <form css={formCss} onSubmit={onFormSubmit}>
          <LabeledInput
            autofocus
            id="email"
            placeholder="Your email address"
            label="Work Email"
            value={emailState.email}
            onChange={onEmailChange}
            onBlur={onEmailBlur}
            error={emailState.error}
            tabIndex={1}
            dark
          />
          <input type="submit" css={hiddenInputCss} />
        </form>
        {loadingEmailAllowed ? (
          <SpinnerIcon />
        ) : (
          <Button
            tabIndex={2}
            onClick={submit}
            variant="primary"
            mode="dark"
            css={continueButtonCss}
          >
            Continue
          </Button>
        )}
      </div>
      {domainIsDisallowed && (
        <section css={darkEmailErrorLabelCss}>
          Please use a work email to sign up for an account or{' '}
          <a
            css={darkLinkCss}
            href={`https://kensho.com/contact/solutions${
              productParam ? `?product=${productParam}` : ''
            }`}
            target="_blank"
            rel="noopener noreferrer"
          >
            contact us
          </a>{' '}
          for assistance.
        </section>
      )}
    </ProductSignup>
  )
}
