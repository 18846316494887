import {createContext} from 'react'

export interface User {
  scopes: string[] // user scopes
  email: string // user’s email
  token: string // user’s auth token
  name: string
  stripeCustomerId: string
  url: string
  username: string
  billingPortalUrl: string
}

export interface UserContextInterface {
  user?: User
  logout: () => void
  _state: 'known' | 'unknown' | 'loading'
  getUser: (isCurrent?: {value: boolean}) => void
}

export default createContext<UserContextInterface>({
  logout: () => null,
  _state: 'unknown',
  getUser: () => {},
})
