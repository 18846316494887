import {css, SerializedStyles} from '@emotion/react'

import assertNever from '../../utils/assertNever'

const primaryLightCss = css`
  background-color: #007694;
  border: 1px solid #fff;
  color: #fff;

  &:hover {
    background-color: #004d61;
  }

  &:active {
    background-color: #6d6d6d;
  }
`

const primaryDarkCss = css`
  background-color: #00b9e8;
  border: 1px solid #000;
  color: #000;

  &:hover {
    background-color: #88e3fa;
  }

  &:active {
    background-color: #e4e5e5;
  }
`

const secondaryLightCss = css`
  background: transparent;
  border: 1px solid #007694;
  color: #007694;

  a:hover {
    color: #fff;
  }

  &:hover {
    background-color: #007694;
    border-color: #007694;
    color: #fff;
  }

  &:active {
    background-color: #004d61;
    border-color: #004d61;
    color: #fff;
  }
`

const secondaryDarkCss = css`
  background: transparent;
  border: 1px solid #fff;
  color: #fff;

  &:hover {
    background-color: #88e3fa;
    border-color: #88e3fa;
    color: #000;
  }

  &:active {
    background-color: #00b9e8;
    border-color: #00b9e8;
    color: #000;
  }
`

const minimalCss = css`
  background: none;
  border: none;
  color: #007694;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    height: 2em;
    width: 90%;
    transform: scaleX(0);
    border-bottom: 2px solid #007694;
    transform-origin: bottom;
    transition: transform 0.25s ease-out;
  }

  &:hover:after {
    transform: scaleX(1);
  }

  &:active {
    color: #6d6d6d;
  }

  &:active:after {
    border-color: #6d6d6d;
  }
`

export type ButtonVariant = 'primary' | 'secondary' | 'minimal'

export function getButtonStyles(variant: ButtonVariant, mode: 'light' | 'dark'): SerializedStyles {
  switch (variant) {
    case 'primary':
      return mode === 'dark' ? primaryDarkCss : primaryLightCss
    case 'secondary':
      return mode === 'dark' ? secondaryDarkCss : secondaryLightCss
    case 'minimal':
      return minimalCss
    default:
      return assertNever(variant)
  }
}
