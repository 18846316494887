import {css} from '@emotion/react'

import KenshoLogoBlue from '../images/kensho-logo-dark-blue.svg'
import {BREAKPOINTS} from '../styleConstants'

import ButtonLink from './Button/ButtonLink'
import UserInfo from './UserInfo'

const TOP_BAR_HEIGHT = 70

const headerCss = css``

const topBarCss = css`
  background: #000000;
  border-bottom: 1px solid black;
  height: ${TOP_BAR_HEIGHT}px;
  overflow-y: hidden;
  display: flex;
  justify-content: space-between;
  flex: 0 0 auto;
`

const supportCss = css`
  color: #00b9e8;
  outline: none;
  text-decoration: none;
  font-size: 16px;
  white-space: nowrap;
  padding: 10px;

  @media screen and (max-width: ${BREAKPOINTS.SMALL}) {
    display: none;
  }
`

const leftContentCss = css`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0 70px;

  @media screen and (max-width: ${BREAKPOINTS.MEDIUM}) {
    padding: 0 10px;
  }
`

const rightContentCss = css`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  padding-right: 70px;
  gap: 20px;

  @media screen and (max-width: ${BREAKPOINTS.MEDIUM}) {
    padding: 0 10px;
  }
`

const developersLogoCss = css`
  height: 24px;
  margin: 0;
`

const freeTrialButtonCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  font-size: 16px;
`

export default function SolutionsHeader({loggedIn}: {loggedIn: boolean}): JSX.Element {
  return (
    <header css={headerCss}>
      <div css={topBarCss}>
        <div css={leftContentCss}>
          <img css={developersLogoCss} src={KenshoLogoBlue} alt="Kensho Developers" />
        </div>
        <div css={rightContentCss}>
          <ButtonLink
            variant="minimal"
            to="https://www.kensho.com/contact"
            css={supportCss}
            target="_blank"
            rel="noopener noreferrer"
          >
            Support
          </ButtonLink>

          <UserInfo />
          {!loggedIn && (
            <ButtonLink
              variant="primary"
              mode="dark"
              to="/free-trial"
              css={freeTrialButtonCss}
              target="_blank"
              rel="noopener noreferrer"
            >
              {' '}
              Free Trial
            </ButtonLink>
          )}
        </div>
      </div>
    </header>
  )
}
