import {useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'

import {productCardInfo} from '../constants/productCardInformation'

const PATHS_TO_REDIRECT = new Set(['', '/free-trial', '/welcome'])

export default function ProductParamRedirect(): null {
  const {search, pathname} = useLocation()
  const navigate = useNavigate()

  const target = pathname.endsWith('/') ? pathname.slice(0, pathname.length - 1) : pathname

  useEffect(() => {
    const URLParams = new URLSearchParams(search)
    const productURLParam = URLParams.get('product')
    if (productURLParam && productURLParam in productCardInfo && PATHS_TO_REDIRECT.has(target)) {
      navigate(`${target}/${productURLParam}`, {replace: true})
    }
  }, [search, navigate, target])
  return null
}
