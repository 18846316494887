import {css} from '@emotion/react'

const iconCss = css`
  @keyframes spin {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  animation: spin 1s linear infinite;
  stroke: #50afc6;

  path {
    stroke: white;
  }
`
const cos30 = Math.cos((30 / 180) * Math.PI)

interface IconProps {
  size?: number
}

export default function SpinnerIcon(props: IconProps): JSX.Element {
  const {size = 48} = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="-32 -32 64 64"
      height={size}
      width={size}
      css={iconCss}
    >
      <title>Loading Spinner</title>
      <circle fill="none" strokeWidth="4" cx="0" cy="0" r="30" />
      <path d={`M 0 -30 A 30 30 0 0 1 ${30 * cos30} 15`} fill="none" strokeWidth="4" />
    </svg>
  )
}
