import {css} from '@emotion/react'

import CustomNavLink from './CustomNavLink'

const LINK_ACTIVE_CLASS_NAME = 'active'
const LINK_PADDING = 8

const navCss = css`
  background: #292f31;
  height: 50px;
  overflow-x: auto;
  overflow-y: hidden;
`

const navListCss = css`
  display: flex;

  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0 16px;
  height: 100%;

  > li {
    height: 100%;
  }
`

const linkCss = css`
  display: flex;
  align-items: center;
  position: relative;
  color: rgba(255, 255, 255, 0.72);
  outline: none;
  text-decoration: none;
  margin-right: 20px;
  font-size: 16px;
  height: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  transition: 0.1s linear;
  padding: 0 ${LINK_PADDING}px;

  &:after {
    content: '';
    position: absolute;
    left: ${LINK_PADDING}px;
    bottom: 0;
    height: 3px;
    width: calc(100% - ${LINK_PADDING * 2}px);
  }

  &.${LINK_ACTIVE_CLASS_NAME}, &.${LINK_ACTIVE_CLASS_NAME}:hover {
    font-weight: bold;
    color: #90dbec;
    &:after {
      background-color: #90dbec;
    }
  }

  &:hover {
    background-color: #353b3e;
    color: white;
    &:after {
      background-color: rgba(255, 255, 255, 0.54);
    }
  }
`

interface NavItem {
  url: string
  title: string
}

export interface NavbarProps {
  navItems: NavItem[]
}

export default function Navbar({navItems = []}: NavbarProps): JSX.Element {
  return (
    <nav css={navCss}>
      <ul css={navListCss}>
        {navItems.map((item) => (
          <CustomNavLink
            key={item.title}
            to={item.url}
            css={linkCss}
            activeClassName={LINK_ACTIVE_CLASS_NAME}
          >
            {item.title}
          </CustomNavLink>
        ))}
      </ul>
    </nav>
  )
}
