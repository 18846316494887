import {NavLink, NavLinkProps} from 'react-router-dom'

function buildClassName(isActive: boolean, className = '', activeClassName = ''): string {
  if (!isActive) return className
  return [activeClassName, className].filter(Boolean).join(' ')
}

interface CustomNavLinkProps extends NavLinkProps {
  activeClassName?: string
  className?: string
}

export default function CustomNavLink(props: CustomNavLinkProps): JSX.Element | null {
  const {className, activeClassName, ...rest} = props
  return (
    <NavLink
      className={({isActive}) => buildClassName(isActive, className, activeClassName)}
      {...rest}
    />
  )
}
