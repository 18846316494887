import {css, SerializedStyles} from '@emotion/react'
import {useDocumentTitle} from '@kensho/tacklebox'
import {useContext} from 'react'

import kenshoLogo from '../../images/kensho-logo-dark-blue.svg'
import genericBackgroundLarge from '../../images/products/GenericLarge.png'
import genericBackgroundMedium from '../../images/products/GenericMedium.png'
import ProductCardInfoProps from '../../constants/productCardInformation'
import {STANDARD_BREAKPOINTS} from '../../styleConstants'
import getBackgroundCss from '../../utils/getBackgroundCss'
import {H1} from '../../components/headings'
import SignUpModeContext from '../../providers/SignUpModeContext'

const PRODUCT_NOT_SPECIFIED_INFO: ProductNotSpecifiedInfo = {
  signupDocumentTitle: 'Free Trial | Kensho',
  signupBackgroundCss: getBackgroundCss(genericBackgroundMedium, genericBackgroundLarge),
  signupDetails: "We illuminate insights in the world's data",
  signupFormSubtitle: "Try out all of the services in Kensho's AI Toolkit!",
}

const mainCss = css`
  overflow: hidden;
  background-size: cover;
  flex-direction: column;
  justify-content: center;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
  background-color: black;
  background-position: calc(100%) calc(100% + 310px);

  @media screen and (max-width: ${STANDARD_BREAKPOINTS.DESKTOP}) {
    align-items: start;
  }
`

const vignetteCss = css`
  position: relative;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) calc(50% - 930px),
    rgba(0, 0, 0, 0) calc(50% + 930px),
    rgba(0, 0, 0, 1) 100%
  );
`

const fallbackBackgroundCss = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: cover;
  z-index: -2;
  background: linear-gradient(128.62deg, #000000 -1.41%, #00010b 30.37%, #000952 100%);
`

const contentCss = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 150px 80px 0;
  max-width: 100%;
  gap: 40px;

  @media screen and (max-width: ${STANDARD_BREAKPOINTS.DESKTOP}) {
    flex-direction: column;
    padding: 150px 50px 0 50px;
    justify-content: start;
    margin-left: 0;
  }

  @media screen and (max-width: ${STANDARD_BREAKPOINTS.TABLET}) {
    flex-direction: column;
    padding: 100px 30px 0 30px;
    justify-content: start;
    margin-left: 0;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 56px);
  }
`

const headerCss = css`
  display: flex;
  width: 100%;
  max-width: ${STANDARD_BREAKPOINTS.WIDE};
  padding: 8px 0 0 8px;
`

const kenshoLogoCss = css`
  width: 150px;
  margin: 20px 10px 0;
`

const titleLogoCss = css`
  height: 20px;
`

const logoLinkCss = css`
  margin-top: 70px;
  height: 20px;
`

const productDescriptionContainerCss = css`
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
`

const productDescriptionCss = css`
  color: white;
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  padding-top: 20px;
  flex-wrap: wrap;
  width: 100%;
  max-width: 700px;

  @media screen and (max-width: ${STANDARD_BREAKPOINTS.TABLET}) {
    font-weight: 700;
    font-size: 32px;
    line-height: 42px;
  }
`

const signupContainerCss = css`
  flex: 0 1 auto;
  color: white;
  padding-top: 64px;
  padding-bottom: 100px;

  @media screen and (max-width: ${STANDARD_BREAKPOINTS.DESKTOP}) {
    padding-top: 30px;
  }
`

const formTitleCss = css`
  font-weight: 700;
  line-height: 28px;
  vertical-align: center;
  font-size: 24px;
`

const formDescriptionCss = css`
  margin-top: 8px;
  font-size: 16px;
`

const iconDarkCss = css`
  height: 150px;
  align-self: start;
`

interface ProductNotSpecifiedInfo extends Partial<ProductCardInfoProps> {
  signupDocumentTitle: string
  signupBackgroundCss: SerializedStyles
  signupDetails: string
  signupFormSubtitle: string
}

interface ProductSignup {
  children: React.ReactNode
  product?: ProductCardInfoProps | ProductNotSpecifiedInfo
}

export default function ProductSignup({
  children,
  product = PRODUCT_NOT_SPECIFIED_INFO,
}: ProductSignup): JSX.Element {
  const signUpMode = useContext(SignUpModeContext)
  useDocumentTitle(signUpMode === 'generic' ? 'Sign Up | Kensho' : product.signupDocumentTitle)

  return (
    <main css={[mainCss, product.signupBackgroundCss]}>
      <div css={vignetteCss}>
        <div css={fallbackBackgroundCss} />
        {product.iconDark && (
          <header css={headerCss}>
            <a href="https://kensho.com" rel="noopener noreferrer" target="_blank">
              <img css={kenshoLogoCss} src={kenshoLogo} alt="Kensho Developers" />
            </a>
          </header>
        )}
        <div css={contentCss}>
          <div css={productDescriptionContainerCss}>
            {product.iconDark ? (
              <img css={iconDarkCss} src={product.iconDark} alt={product.productName} />
            ) : (
              <a
                css={logoLinkCss}
                href="https://kensho.com"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img css={titleLogoCss} src={kenshoLogo} alt="Kensho Developers" />
              </a>
            )}
            <H1 css={productDescriptionCss}>{product.signupDetails}</H1>
          </div>
          <div css={signupContainerCss}>
            <h3 css={formTitleCss}>
              {signUpMode === 'generic' ? 'Create your Kensho account' : 'Start your free trial'}
            </h3>
            <p css={formDescriptionCss}>{product.signupFormSubtitle}</p>
            {children}
          </div>
        </div>
      </div>
    </main>
  )
}
