import {css} from '@emotion/react'
import {useDocumentTitle} from '@kensho/tacklebox'
import {useSearchParams} from 'react-router-dom'

import kenshoLogo from '../../images/kensho-logo-blue.svg'
import {productCardInfo as productInfo} from '../../constants/productCardInformation'
import ProductIcon from '../../components/ProductIcon'
import {BREAKPOINTS} from '../../styleConstants'

const pageCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: white;
`

const formTitleCss = css`
  font-weight: 700;
  line-height: 28px;
  vertical-align: center;
  font-size: 28px;
  text-align: center;
`

const logoCss = css`
  height: 21px;
  margin-bottom: 24px;
`

const sectionCss = css`
  width: 370px;
  margin: 0 0 32px;
  padding: 36px;
  border: 2px solid rgb(238, 238, 238);

  @media screen and (max-width: ${BREAKPOINTS.SMALL}) {
    border: none;
    padding-top: 50px;
  }
`

const productIconCss = css`
  margin-bottom: 24px;
  margin-right: 24px;
  display: flex;
  justify-content: center;
`

interface SignupPageProps {
  children: React.ReactNode
  title?: string
  className?: string
  pageTitle?: string | null
}

export default function SignupPage({
  children,
  title,
  className,
  pageTitle = null,
}: SignupPageProps): JSX.Element {
  useDocumentTitle(pageTitle)

  const [query] = useSearchParams()
  const productParam = query.get('product')

  const isValidProduct = productParam && productParam in productInfo

  return (
    <main css={pageCss} className={className}>
      <section css={sectionCss}>
        {productParam && isValidProduct ? (
          <ProductIcon css={productIconCss} product={productInfo[productParam]} />
        ) : (
          <img css={logoCss} src={kenshoLogo} alt="Kensho Logo" />
        )}
        {title && <h3 css={formTitleCss}>{title}</h3>}
        {children}
      </section>
    </main>
  )
}
