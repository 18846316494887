import {AsyncValue} from '@kensho/tacklebox'

import useBaseFetch from '../hooks/useBaseFetch'

export interface LoginResponse {
  loggedIn: boolean
  email: string
  name: string
  url: string
  username: string
}

export default function useUser(): AsyncValue<LoginResponse> {
  return useBaseFetch('/users/v1/is_logged_in/')
}
