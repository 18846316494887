import {useContext, useState} from 'react'
import {useLocation, Location} from 'react-router-dom'
import {css} from '@emotion/react'

import SignUpModeContext from '../../providers/SignUpModeContext'

import SignupPage from './SignupPage'

const pageCss = css`
  section {
    width: 392px;
  }
`

const formTitleCss = css`
  font-weight: 700;
  line-height: 28px;
  vertical-align: center;
  font-size: 18px;
`

const formDescriptionCss = css`
  font-size: 16px;
  margin: 26px 0;
  text-align: left;
  line-height: 20px;
`

const smallCss = css`
  font-size: 14px;
  text-align: left;
  margin: 26px 0 0;
  line-height: 18px;

  a {
    font-weight: bold;
  }
`

const getInitialEmail = (location: Location): string =>
  new URLSearchParams(location.search).get('email') || ''

export default function Verify(): JSX.Element {
  const location = useLocation()
  const [email] = useState(getInitialEmail(location))
  const signUpMode = useContext(SignUpModeContext)

  return (
    <SignupPage pageTitle="Verify Email | Kensho" css={pageCss}>
      <h3 css={formTitleCss}>We sent a verification email to: {email}</h3>
      <p css={formDescriptionCss}>
        Please check your email and follow the verification link to activate your new account.
      </p>
      <p css={smallCss}>Don&apos;t see the email? Be sure to check your spam folder.</p>
      {signUpMode === 'free-trial' && (
        <p css={smallCss}>
          After verifying your email, please <a href="/users/login">sign in here</a>
        </p>
      )}
    </SignupPage>
  )
}
