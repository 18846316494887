import {createContext} from 'react'

import {ProductMetadataState} from './types'

interface ProductMetadataContext {
  state: ProductMetadataState
  getProductMetadata: (isCurrent: {value: boolean}) => void
}

export default createContext<ProductMetadataContext>({
  state: {status: 'uninitiated'},
  getProductMetadata: () => {},
})
