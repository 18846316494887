import {css} from '@emotion/react'
import {IconChevronRight} from '@kensho/icons'

import KenshoLogo from '../images/kensho-logo.png'

import UserInfo from './UserInfo'
import Navbar, {NavbarProps} from './Navbar'

const TOP_BAR_HEIGHT = 50

const headerCss = css``

const topBarCss = css`
  background: #353b3e;
  border-bottom: 1px solid black;
  height: ${TOP_BAR_HEIGHT}px;
  overflow-y: hidden;
  display: flex;
  justify-content: space-between;
  flex: 0 0 auto;
`

const titleCss = css`
  margin: 0;
  color: rgba(255, 255, 255, 0.87);
  outline: none;
  text-decoration: none;
  font-size: 14px;
`

const leftContentCss = css`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0 12px;
`

const rightContentCss = css`
  flex: 0 0 auto;
  display: flex;
  align-items: center;

  p {
    padding: 0 10px;
  }
`

const developersLogoCss = css`
  height: 24px;
  margin: 0;
`

const pathSeparatorCss = css`
  color: white;
  margin: 0 5px;
`

interface HeaderProps {
  navItems: NavbarProps['navItems']
}

export default function Header({navItems}: HeaderProps): JSX.Element {
  return (
    <header css={headerCss}>
      <div css={topBarCss}>
        <div css={leftContentCss}>
          <img css={developersLogoCss} src={KenshoLogo} alt="Kensho Developers" />
          <IconChevronRight css={pathSeparatorCss} size={30} />
          <p css={titleCss}>My Account</p>
        </div>
        <div css={rightContentCss}>
          <a
            href="https://www.kensho.com/contact"
            css={titleCss}
            target="_blank"
            rel="noopener noreferrer"
          >
            Support
          </a>

          <UserInfo />
        </div>
      </div>
      <Navbar navItems={navItems} />
    </header>
  )
}
