import {css} from '@emotion/react'

import {STANDARD_BREAKPOINTS} from '../../styleConstants'

export const labelCss = css`
  display: block;
  font-weight: bold;
  line-height: 18px;
  margin: 0;
  font-size: 14px;
`

export const darkLabelCss = css`
  font-size: 12px;
  color: #ff0022;
  font-weight: 700;
`

export const errorLabelCss = css`
  font-size: 12px;
  color: #db374d;
  margin-left: 10px;
`

export const inputErrorCss = css`
  border: 2px solid #db374d;
  margin-bottom: 0px;

  &:hover:not(:focus) {
    border: 2px solid #db374d;
  }
`

export const inputCss = css`
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 0;
  padding: 4px 8px;
  width: 320px;
  margin-top: 6px;
  max-width: 100%;
  display: block;
  font-weight: normal;
  height: 36px;

  &:invalid {
    border: 2px solid red;
  }

  &:focus {
    border: 2px solid rgba(9, 129, 159, 0.5);
  }

  &:hover:not(:focus) {
    border-color: rgba(0, 0, 0, 0.54);
  }

  @media screen and (max-width: ${STANDARD_BREAKPOINTS.TABLET}) {
    display: flex;
    width: 100%;
    flex: 1;
  }
`
