import {mapKeys, camelCase, mapValues, isArray, isPlainObject} from 'lodash'

export default function camelCaseDeep<T>(item: T): T {
  if (isPlainObject(item)) {
    const camelCased = mapKeys(item as Record<string, unknown>, (_, key) => camelCase(key))
    return mapValues(camelCased, camelCaseDeep) as T
  }
  if (isArray(item)) {
    return item.map(camelCaseDeep) as unknown as T
  }
  return item
}
