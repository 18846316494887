import {css} from '@emotion/react'
import {PropGetters} from 'downshift'

import {BREAKPOINTS} from '../styleConstants'

import {HighlightedSubstring} from './HighlightedSubstring'

const liCss = css`
  list-style-type: none;
  padding: 4px 8px;
  height: 36px;
  align-items: center;
  display: flex;
  position: relative;
  width: 294px;
  @media screen and (max-width: ${BREAKPOINTS.SMALL}) {
    width: 298px;
  }
`

export interface Item {
  [value: string]: string
}
interface TypeaheadFilteringProps {
  getItemProps: PropGetters<Item>['getItemProps']
  isOpen: boolean
  inputValue: string | null
  highlightedIndex: number | null
  items: Item[]
}

export function TypeaheadFiltering({
  getItemProps,
  isOpen,
  inputValue,
  highlightedIndex,
  items,
}: TypeaheadFilteringProps): JSX.Element | null {
  if (!isOpen) return <></>
  const itemsToRender = items.filter(
    (item: Item) => !inputValue || item.value.toLowerCase().includes(inputValue.toLowerCase()),
  )
  return (
    <>
      {itemsToRender.map((item, index) => (
        <li
          css={liCss}
          {...getItemProps({
            key: item.value,
            index,
            item,
            style: {
              backgroundColor: highlightedIndex === index ? '#E6E6E6' : 'white',
            },
          })}
        >
          <HighlightedSubstring match={inputValue} text={item.value} />
        </li>
      ))}
    </>
  )
}
