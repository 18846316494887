import {css} from '@emotion/react'
import Downshift, {DownshiftProps} from 'downshift'

import {BREAKPOINTS} from '../styleConstants'

import {Item, TypeaheadFiltering} from './TypeaheadFiltering'
import {labelCss, errorLabelCss, darkLabelCss, inputErrorCss, inputCss} from './inputs/styles'

const ulCss = css`
  margin: 0;
  padding: 0;
  z-index: 1;
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  overflow: auto;
  max-height: 215px;
  @media screen and (max-width: ${BREAKPOINTS.SMALL}) {
    width: 298px;
  }
`

const inputContainerCss = css`
  margin: 24px 0 0;
`

interface TypeaheadProps {
  items: Item[]
  label?: string
  selectedValue?: string
  id: string
  error?: string
  dark?: boolean
  onChange: DownshiftProps<Item>['onChange']
}

export default function Typeahead({
  items,
  label,
  id,
  onChange,
  error = '',
  dark,
}: TypeaheadProps): JSX.Element {
  const hasError = error !== ''

  return (
    <Downshift onChange={onChange} itemToString={(item) => (item ? item.value : '')}>
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        inputValue,
        highlightedIndex,
        getRootProps,
      }) => (
        <div css={[inputContainerCss]}>
          {label && (
            <label css={labelCss} htmlFor={id}>
              {label}
            </label>
          )}
          <div {...getRootProps({}, {suppressRefError: true})}>
            <input {...getInputProps()} id={id} css={[inputCss, hasError && inputErrorCss]} />
          </div>
          <ul {...getMenuProps()} css={ulCss}>
            <TypeaheadFiltering
              getItemProps={getItemProps}
              isOpen={isOpen}
              inputValue={inputValue}
              highlightedIndex={highlightedIndex}
              items={items}
            />
          </ul>

          {hasError && (
            <label css={dark ? darkLabelCss : errorLabelCss} htmlFor={id}>
              {error}
            </label>
          )}
        </div>
      )}
    </Downshift>
  )
}
