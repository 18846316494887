import {AsyncValue} from '@kensho/tacklebox'

import useBaseFetch from '../hooks/useBaseFetch'

export interface StripePricingPlan {
  id: string
  object: string
  active: boolean
  aggregateUsage: string
  amount: number
  amountDecimal: number
  billingScheme: string
  created: number
  currency: string
  interval: string
  intervalCount: number
  metadata?: {
    [key: string]: string
  }
  product: string
  tiersMode?: string
  trialPeriodDays?: number
  usageType: string
}

export interface PricingResponse {
  name: string // "Pay as you go", "Monthly", "Standard"
  description: string // short description of the billing type
  product: string // associated stripe product id
  stripePriceId: string
  stripeInfo: StripePricingPlan
}

interface FetchPricingOptions {
  stripePriceId?: string
  subscribedPlansOnly?: boolean
}

/**
 * Fetch the pricing options for a specific product
 *
 * @param params Optional parameters to use when fetching the pricing plans
 * @param params.stripePriceId The Stripe price id of the product for which we want price information.
 * @param params.subscribedPlansOnly Whether or not we should only fetch pricing plans that this user is subscribed to
 */
export default function usePricing(params: FetchPricingOptions): AsyncValue<PricingResponse[]> {
  return useBaseFetch<FetchPricingOptions, PricingResponse[]>('/api/v1/pricing/', params)
}
