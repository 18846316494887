import Cookies from 'js-cookie'

export default function logout(): Promise<never> {
  const apiEndpoint = '/users/logout'
  const csrftoken = Cookies.get('csrftoken')
  if (!csrftoken) throw new Error('No CSRF token found')
  const request = new Request(apiEndpoint, {headers: {'X-CSRFToken': csrftoken}})
  const params = {method: 'POST'}
  return fetch(request, params).then((response) => {
    if (response.ok) {
      window.location.href = '/'
    }
    throw response
  })
}
