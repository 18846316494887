import {Link} from 'react-router-dom'
import {css} from '@emotion/react'
import {Button} from '@kensho/ui'

import {BREAKPOINTS} from '../styleConstants'
import ProductCardInfoProps from '../constants/productCardInformation'

import ProductIcon from './ProductIcon'

const productCardCss = css`
  display: flex;
  margin: 15px 65px;
  background: #ffffff;
  padding: 25px;
  border: 1px solid #e4e5e5;
  flex-wrap: wrap;
  align-items: center;
  max-width: ${BREAKPOINTS.LARGE};

  @media screen and (min-width: ${BREAKPOINTS.LARGE}) {
    width: 100%;
  }

  @media screen and (max-width: ${BREAKPOINTS.MEDIUM}) {
    padding: 15px;
    margin: 10px 10px;
  }

  @media screen and (max-width: ${BREAKPOINTS.SMALL}) {
    padding: 20px;
    margin: 10px 0;
    box-shadow: none;
  }
`

const columnCss = css`
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-self: flex-start;

  @media screen and (max-width: ${BREAKPOINTS.MEDIUM}) {
    flex: 1 0 100%;
  }

  @media screen and (max-width: ${BREAKPOINTS.SMALL}) {
    flex: 1 0 100%;
    padding: 10px;
  }
`

const leftColumnCss = css`
  flex: 1.3;
  flex-wrap: wrap;
  justify-content: space-around;
`

const middleColumnCss = css`
  flex: 1.5;

  @media screen and (max-width: ${BREAKPOINTS.MEDIUM}) {
    padding-top: 12px;
  }

  @media screen and (max-width: ${BREAKPOINTS.SMALL}) {
    display: none;
  }
`

const rightColumnCss = css`
  flex: 1;
  gap: 40px;
  align-items: center;

  @media screen and (max-width: ${BREAKPOINTS.MEDIUM}) {
    align-items: normal;
    padding-top: 0;
    gap: 32px;
  }
`

const titleCss = css`
  color: #0b008f;
  font-weight: 700;
  font-size: 16px;
  padding-bottom: 20px;

  @media screen and (max-width: ${BREAKPOINTS.MEDIUM}) {
    padding-bottom: 10px;
  }
`

const productIconCss = css`
  padding: 10px;
`

const productSummaryCss = css`
  font-weight: 700;
  font-size: 16px;
  margin-top: 10px;

  @media screen and (max-width: ${BREAKPOINTS.SMALL}) {
    margin-bottom: 22px;
  }
`

const detailCss = css`
  font-size: 18px;
  font-weight: 400;
`

const ulCss = css`
  padding-inline-start: 15px;
`

const detailPointsCss = css`
  font-weight: 400;
  font-size: 18px;
  padding: 12px;
  list-style: none;
  position: relative;
  ::before {
    content: ' ';
    position: absolute;
    background: transparent;
    width: 14px;
    height: 14px;
    border: 4px solid black;
    border-radius: 50%;
    left: -15px;
    top: 15px;
  }
`

const externalLinkCss = css`
  color: #09819f;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  text-decoration: none;

  @media screen and (max-width: ${BREAKPOINTS.SMALL}) {
    padding-bottom: 20px;
  }
`

const trialDetailsCss = css`
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  color: #000000;

  @media screen and (max-width: ${BREAKPOINTS.MEDIUM}) {
    text-align: left;
  }
`

const buttonCss = css`
  position: relative;
  width: 100%;
  span {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`

const productLinkCss = css`
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: white;
  text-decoration: none;
`

interface ProductCardProps {
  product: ProductCardInfoProps
  loggedIn?: boolean
  showFreeTrialLink?: boolean
}

export function RightColumn({product, loggedIn, showFreeTrialLink}: ProductCardProps): JSX.Element {
  const {productName} = product
  return (
    <div css={[columnCss, rightColumnCss]}>
      <p css={trialDetailsCss}>{product.trialDetails}</p>
      <Button size="large" css={buttonCss} intent="primary">
        <a href={product.signInLink} css={productLinkCss} target="_blank" rel="noopener noreferrer">
          Sign in to {productName}
        </a>
      </Button>
      {showFreeTrialLink && !loggedIn && (
        <Link to={`/free-trial/${product.id}`} css={externalLinkCss}>
          Don&apos;t have an account? Sign up for {productName}
        </Link>
      )}
      <a href={product.contactLink} css={externalLinkCss} target="_blank" rel="noopener noreferrer">
        Contact {productName}
      </a>
    </div>
  )
}

export default function ProductCard({
  product,
  loggedIn,
  showFreeTrialLink = true,
}: ProductCardProps): JSX.Element {
  return (
    <section css={productCardCss}>
      <div css={[columnCss, leftColumnCss]}>
        <p css={titleCss}>{product.title}</p>
        <ProductIcon css={productIconCss} product={product} />
        <p css={productSummaryCss}>{product.summary}</p>
      </div>
      <div css={[columnCss, middleColumnCss]}>
        <p css={detailCss}>{product.details}</p>
        <ul css={ulCss}>
          {product.detailsList.map((point) => (
            <li key={typeof point === 'string' ? point : point.key} css={detailPointsCss}>
              {point}
            </li>
          ))}
        </ul>
      </div>
      <RightColumn product={product} loggedIn={loggedIn} showFreeTrialLink={showFreeTrialLink} />
    </section>
  )
}
