import {css} from '@emotion/react'

import {H1, H2} from '../components/headings'
import {launchProductCardList} from '../constants/productCardInformation'
import WelcomeBackground from '../images/welcome-background.png'
import {BREAKPOINTS} from '../styleConstants'
import SolutionsHeader from '../components/SolutionsHeader'
import CompactProductCard from '../components/CompactProductCard'

const mainCss = css`
  width: 100%;
`

const heroCss = css`
  position: relative;
  display: inline-block;
  width: 100%;
`

const welcomeTextCss = css`
  margin: 0;
  font-weight: 700;
  font-size: 10vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${WelcomeBackground});
  text-align: center;
  color: #ffffff;
  height: 35vh;
  vertical-align: middle;
  background-size: cover;
`

const introductionContainerCss = css`
  display: flex;
  flex-direction: column;
  padding: 70px;
  background: #ffffff;
  flex-wrap: wrap;
  @media screen and (max-width: ${BREAKPOINTS.SMALL}) {
    padding: 40px;
  }
`

const freeTrialsContainerCss = css`
  align-self: center;
`

const productTextCss = css`
  font-size: 18px;
  line-height: 50px;
`

const headerTextCss = css`
  margin: 0;
  flex: 1 0 35%;
  @media screen and (max-width: ${BREAKPOINTS.MEDIUM}) {
    flex: 1 0 100%;
  }
`

const headerTitleCss = css`
  font-size: 48px;
  line-height: 50px;
  @media screen and (max-width: ${BREAKPOINTS.XSMALL}) {
    font-size: 36px;
  }
`

const subtitleTextCss = css`
  line-height: 35px;
  font-size: 32px;
  letter-spacing: 0.02em;
  color: rgba(6, 3, 3, 0.95);
  @media screen and (max-width: ${BREAKPOINTS.XSMALL}) {
    font-size: 28px;
  }
`

const horizontalContainerCss = css`
  display: flex;
  gap: 30px;
  width: 100%;
  flex-wrap: wrap;
  max-width: ${BREAKPOINTS.LARGE};
  padding-top: 70px;
`

const productCardsCss = css`
  margin: 40px 70px 120px 70px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 25px;
  @media (max-width: ${BREAKPOINTS.MEDIUM}) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: ${BREAKPOINTS.SMALL}) {
    grid-template-columns: 1fr;
    margin: 30px 40px;
    max-width: 100%;
  }
`

interface LaunchProductsPageProps {
  taglineText: string
  headerTitleText: string
  showFreeTrialLink?: boolean
}

export default function LaunchProductsPage({
  taglineText,
  headerTitleText,
}: LaunchProductsPageProps): JSX.Element {
  const headerSubtitleText = "Use Kensho's AI Toolkit to start uncovering insights in your data."

  return (
    <main css={mainCss}>
      <SolutionsHeader loggedIn />
      <header css={heroCss}>
        <H1 css={welcomeTextCss}>{taglineText}</H1>
      </header>
      <div css={introductionContainerCss}>
        <div css={freeTrialsContainerCss}>
          <H2 css={productTextCss}>OUR PRODUCTS</H2>
          <div css={horizontalContainerCss}>
            <H2 css={[headerTextCss, headerTitleCss]}>{headerTitleText}</H2>
            <p css={[headerTextCss, subtitleTextCss]}>{headerSubtitleText}</p>
          </div>
        </div>
      </div>
      <div css={productCardsCss}>
        {launchProductCardList.map((product) => (
          <CompactProductCard key={product.id} product={product} />
        ))}
      </div>
    </main>
  )
}
