import {css} from '@emotion/react'
import {useParams} from 'react-router-dom'

import {H1, H2} from '../components/headings'
import ProductCard from '../components/ProductCard'
import Footer from '../components/Footer'
import ProductCardInfoProps from '../constants/productCardInformation'
import WelcomeBackground from '../images/welcome-background.png'
import {BREAKPOINTS} from '../styleConstants'
import SolutionsHeader from '../components/SolutionsHeader'
import SpinnerIcon from '../components/SpinnerIcon'
import useUser from '../api/useUser'

import LaunchProductsPage from './LaunchProductsPage'

const mainCss = css`
  width: 100%;
`

const heroCss = css`
  position: relative;
  display: inline-block;
  width: 100%;
`

const welcomeTextCss = css`
  margin: 0;
  font-weight: 700;
  font-size: 10vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${WelcomeBackground});
  text-align: center;
  color: #ffffff;
  height: 35vh;
  vertical-align: middle;
  background-size: cover;
`

const introductionContainerCss = css`
  display: flex;
  flex-direction: column;
  padding: 80px;
  background: #ffffff;
  flex-wrap: wrap;

  @media screen and (max-width: ${BREAKPOINTS.SMALL}) {
    padding: 40px;
  }
`

const freeTrialsContainerCss = css`
  align-self: center;
`

const headerTextCss = css`
  margin: 0;
  flex: 1 0 35%;

  @media screen and (max-width: ${BREAKPOINTS.MEDIUM}) {
    flex: 1 0 100%;
  }
`

const headerTitleCss = css`
  font-size: 48px;
  line-height: 50px;

  @media screen and (max-width: ${BREAKPOINTS.XSMALL}) {
    font-size: 36px;
  }
`

const subtitleTextCss = css`
  font-weight: 400;
  line-height: 35px;
  font-size: 32px;
  letter-spacing: 0.02em;
  color: rgba(6, 3, 3, 0.95);

  @media screen and (max-width: ${BREAKPOINTS.XSMALL}) {
    font-size: 28px;
  }
`

const horizontalContainerCss = css`
  display: flex;
  gap: 30px;
  width: 100%;
  flex-wrap: wrap;
  max-width: ${BREAKPOINTS.LARGE};
`

const productCardsCss = css`
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;
  padding: 40px 0;
  width: 100%;
  align-items: center;

  @media screen and (max-width: ${BREAKPOINTS.MEDIUM}) {
    padding: 15px;
  }

  @media screen and (max-width: ${BREAKPOINTS.SMALL}) {
    padding: 10px 0;
  }
`

const footerDivCss = css`
  display: flex;
  background-color: #f8f8f8;
  width: 100%;
  justify-content: center;
  padding: 0px 65px 60px 65px;
  margin: 0;

  @media screen and (max-width: ${BREAKPOINTS.MEDIUM}) {
    padding: 20px 25px 30px 25px;
  }

  @media screen and (max-width: ${BREAKPOINTS.SMALL}) {
    padding: 30px 0;
  }
`

const loadingContainerCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
`

interface LandingPageProps {
  taglineText: string
  headerTitleText: string
  headerSubtitleText: string
  productCardList: ProductCardInfoProps[]
  showFreeTrialLink?: boolean
}

export default function LandingPage({
  taglineText,
  headerTitleText,
  headerSubtitleText,
  productCardList,
  showFreeTrialLink = false,
}: LandingPageProps): JSX.Element {
  const userData = useUser()

  const {product: productParam} = useParams()

  if (productParam) {
    const productIndex = productCardList.findIndex((product) => product.id === productParam)
    const productHead = productCardList.splice(productIndex, 1)
    productCardList.unshift(productHead[0])
  }

  if (userData.status === 'pending') {
    return (
      <div css={loadingContainerCss}>
        <SpinnerIcon />
      </div>
    )
  }

  const loggedIn = userData.status === 'ready' && userData.value.loggedIn

  if (loggedIn)
    return <LaunchProductsPage taglineText={taglineText} headerTitleText={headerTitleText} />
  return (
    <main css={mainCss}>
      <SolutionsHeader loggedIn={loggedIn} />
      <header css={heroCss}>
        <H1 css={welcomeTextCss}>{taglineText}</H1>
      </header>
      <div css={introductionContainerCss}>
        <div css={freeTrialsContainerCss}>
          <div css={horizontalContainerCss}>
            <H2 css={[headerTextCss, headerTitleCss]}>{headerTitleText}</H2>
            <p css={[headerTextCss, subtitleTextCss]}>{headerSubtitleText}</p>
          </div>
        </div>
      </div>
      <div css={productCardsCss}>
        {productCardList.map((product) => (
          <ProductCard
            key={product.id}
            showFreeTrialLink={showFreeTrialLink}
            product={product}
            loggedIn={loggedIn}
          />
        ))}
      </div>
      <div css={footerDivCss}>
        <Footer />
      </div>
    </main>
  )
}
