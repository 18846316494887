import {css} from '@emotion/react'

import {ButtonVariant, getButtonStyles} from './getButtonStyles'

const buttonCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 40px;

  font-size: 18px;
  line-height: 21.92px;
  font-weight: 400;

  cursor: pointer;
  transition: all 300ms ease;
  border: 1px solid #007694;
  color: #50afc6;

  &:hover {
    opacity: 1;
  }
`

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant: ButtonVariant
  mode?: 'dark' | 'light'
}

export default function Button({
  className,
  children,
  variant,
  mode = 'light',
  type = 'button',
  ...rest
}: ButtonProps): JSX.Element {
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      css={[buttonCss, getButtonStyles(variant, mode)]}
      className={className}
      {...rest}
    >
      {children}
    </button>
  )
}
