import {css} from '@emotion/react'

export const h4Body2Css = css`
  font-size: 18px;
  line-height: 27px;
`

export const whiteTextCss = css`
  color: #ffffff;
`

export const legalCss = css`
  font-size: 12px;
  line-height: 20px;
`

export const hBoldCss = css`
  font-weight: 700;
`

export const backgroundWhiteCss = css`
  background-color: #ffffff;
`

export const labelCss = css`
  font-size: 16px;
  line-height: 22px;
`
