import {useRef, useEffect} from 'react'
import {css, SerializedStyles} from '@emotion/react'

import {STANDARD_BREAKPOINTS} from '../styleConstants'

import {labelCss, errorLabelCss, darkLabelCss, inputErrorCss, inputCss} from './inputs/styles'

const inputContainerCss = css`
  margin: 24px 0 0;
  height: 76px;

  @media screen and (max-width: ${STANDARD_BREAKPOINTS.TABLET}) {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
  }
`

interface LabeledInputProps extends React.HTMLAttributes<HTMLInputElement> {
  value: string
  autofocus?: boolean
  label?: string
  onChange: React.ChangeEventHandler<HTMLInputElement>
  id: string
  placeholder?: string
  error?: string
  lpIgnore?: boolean
  dark?: boolean
  containerOverrideCss?: SerializedStyles
}

export default function LabeledInput({
  value,
  autofocus = false,
  label,
  onChange,
  id,
  placeholder,
  error = '',
  lpIgnore = true,
  dark,
  containerOverrideCss,
  ...extraInputProps
}: LabeledInputProps): JSX.Element {
  const ref = useRef<HTMLInputElement>(null)

  const hasError = error !== ''
  useEffect(() => {
    if (autofocus && ref.current) ref.current.focus()
  }, [ref, autofocus])

  return (
    <div css={[inputContainerCss, containerOverrideCss]}>
      {label && (
        <label css={labelCss} htmlFor={id}>
          {label}
        </label>
      )}
      <input
        {...extraInputProps}
        id={id}
        ref={ref}
        css={[inputCss, hasError && inputErrorCss]}
        name={id}
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        data-lpignore={lpIgnore}
      />
      {hasError && (
        <label
          data-testid="submission-error"
          css={dark ? darkLabelCss : errorLabelCss}
          htmlFor={id}
        >
          {error}
        </label>
      )}
    </div>
  )
}
