export const countriesList = [
  {value: 'United States'},
  {value: 'Canada'},
  {value: 'Afghanistan'},
  {value: 'Åland Islands'},
  {value: 'Albania'},
  {value: 'Algeria'},
  {value: 'American Samoa'},
  {value: 'Andorra'},
  {value: 'Angola'},
  {value: 'Anguilla'},
  {value: 'Antarctica'},
  {value: 'Antigua & Barbuda'},
  {value: 'Argentina'},
  {value: 'Armenia'},
  {value: 'Aruba'},
  {value: 'Australia'},
  {value: 'Austria'},
  {value: 'Azerbaijan'},
  {value: 'Bahamas'},
  {value: 'Bahrain'},
  {value: 'Bangladesh'},
  {value: 'Barbados'},
  {value: 'Belarus'},
  {value: 'Belgium'},
  {value: 'Belize'},
  {value: 'Benin'},
  {value: 'Bermuda'},
  {value: 'Bhutan'},
  {value: 'Bolivia'},
  {value: 'Bosnia-Herzegovina'},
  {value: 'Botswana'},
  {value: 'Bouvet Island'},
  {value: 'Brazil'},
  {value: 'British Indian Ocean Territory'},
  {value: 'British Virgin Islands'},
  {value: 'Brunei Darussalam'},
  {value: 'Bulgaria'},
  {value: 'Burkina Faso'},
  {value: 'Burundi'},
  {value: 'Cambodia'},
  {value: 'Cameroon'},
  {value: 'Cape Verde'},
  {value: 'Cayman Islands'},
  {value: 'Central African Republic'},
  {value: 'Chad'},
  {value: 'Channel Islands'},
  {value: 'Chile'},
  {value: 'China'},
  {value: 'Christmas Island'},
  {value: 'Cocos Islands'},
  {value: 'Colombia'},
  {value: 'Comoros'},
  {value: 'Congo'},
  {value: 'Cook Islands'},
  {value: 'Costa Rica'},
  {value: 'Croatia'},
  {value: 'Cuba'},
  {value: 'Curaçao'},
  {value: 'Cyprus'},
  {value: 'Czech Republic'},
  {value: 'Denmark'},
  {value: 'Djibouti'},
  {value: 'Dominica'},
  {value: 'Dominican Republic'},
  {value: 'Ecuador'},
  {value: 'Egypt'},
  {value: 'El Salvador'},
  {value: 'Equatorial Guinea'},
  {value: 'Eritrea'},
  {value: 'Estonia'},
  {value: 'Ethiopia'},
  {value: 'Falkland Islands'},
  {value: 'Faroe Islands'},
  {value: 'Fiji'},
  {value: 'Finland'},
  {value: 'France'},
  {value: 'French Guiana'},
  {value: 'French Polynesia'},
  {value: 'French Southern Territories'},
  {value: 'Gabon'},
  {value: 'Gambia'},
  {value: 'Georgia'},
  {value: 'Germany'},
  {value: 'Ghana'},
  {value: 'Gibraltar'},
  {value: 'Greece'},
  {value: 'Greenland'},
  {value: 'Grenada'},
  {value: 'Guadeloupe'},
  {value: 'Guam'},
  {value: 'Guatemala'},
  {value: 'Guernsey'},
  {value: 'Guinea'},
  {value: 'Guinea-Bissau'},
  {value: 'Guyana'},
  {value: 'Haiti'},
  {value: 'Heard Island and McDonald Islands'},
  {value: 'Honduras'},
  {value: 'Hong Kong'},
  {value: 'Hungary'},
  {value: 'Iceland'},
  {value: 'India'},
  {value: 'Indonesia'},
  {value: 'Iran'},
  {value: 'Iraq'},
  {value: 'Ireland'},
  {value: 'Isle of Man'},
  {value: 'Israel'},
  {value: 'Italy'},
  {value: 'Ivory Coast'},
  {value: 'Jamaica'},
  {value: 'Japan'},
  {value: 'Jersey'},
  {value: 'Jordan'},
  {value: 'Kazakhstan'},
  {value: 'Kenya'},
  {value: 'Kiribati'},
  {value: 'Kuwait'},
  {value: 'Kyrgyzstan'},
  {value: 'Laos'},
  {value: 'Latvia'},
  {value: 'Lebanon'},
  {value: 'Lesotho'},
  {value: 'Liberia'},
  {value: 'Libya'},
  {value: 'Liechtenstein'},
  {value: 'Lithuania'},
  {value: 'Luxembourg'},
  {value: 'Macau'},
  {value: 'Macedonia'},
  {value: 'Madagascar'},
  {value: 'Malawi'},
  {value: 'Malaysia'},
  {value: 'Maldives'},
  {value: 'Mali'},
  {value: 'Malta'},
  {value: 'Marshall Islands'},
  {value: 'Martinique'},
  {value: 'Mauritania'},
  {value: 'Mauritius'},
  {value: 'Mayotte'},
  {value: 'Mexico'},
  {value: 'Micronesia, Federated States of'},
  {value: 'Moldova'},
  {value: 'Monaco'},
  {value: 'Mongolia'},
  {value: 'Montenegro'},
  {value: 'Montserrat'},
  {value: 'Morocco'},
  {value: 'Mozambique'},
  {value: 'Myanmar'},
  {value: 'Namibia'},
  {value: 'Nauru'},
  {value: 'Nepal'},
  {value: 'Netherlands Antilles'},
  {value: 'Netherlands'},
  {value: 'New Caledonia'},
  {value: 'New Zealand'},
  {value: 'Nicaragua'},
  {value: 'Niger'},
  {value: 'Nigeria'},
  {value: 'Niue'},
  {value: 'Norfolk Island'},
  {value: 'North Korea'},
  {value: 'Northern Mariana Islands'},
  {value: 'Norway'},
  {value: 'Oman'},
  {value: 'Pakistan'},
  {value: 'Palau'},
  {value: 'Palestinian Authority'},
  {value: 'Panama'},
  {value: 'Papua New Guinea'},
  {value: 'Paraguay'},
  {value: 'Peru'},
  {value: 'Philippines'},
  {value: 'Pitcairn'},
  {value: 'Poland'},
  {value: 'Portugal'},
  {value: 'Puerto Rico'},
  {value: 'Qatar'},
  {value: 'Republic of the Congo'},
  {value: 'Reunion'},
  {value: 'Romania'},
  {value: 'Russia'},
  {value: 'Rwanda'},
  {value: 'Saint Barthélemy'},
  {value: 'Saint Helena'},
  {value: 'Saint Kitts & Nevis'},
  {value: 'Saint Lucia'},
  {value: 'Saint Martin'},
  {value: 'Saint Pierre and Miquelon'},
  {value: 'Saint Vincent & Grenadines'},
  {value: 'Samoa'},
  {value: 'San Marino'},
  {value: 'Sao Tome and Principe'},
  {value: 'Saudi Arabia'},
  {value: 'Senegal'},
  {value: 'Serbia'},
  {value: 'Seychelles'},
  {value: 'Sierra Leone'},
  {value: 'Singapore'},
  {value: 'Slovakia'},
  {value: 'Slovenia'},
  {value: 'Solomon Islands'},
  {value: 'Somalia'},
  {value: 'South Africa'},
  {value: 'South Georgia and the South Sandwich Islands'},
  {value: 'South Korea'},
  {value: 'Spain'},
  {value: 'Sri Lanka'},
  {value: 'Sudan'},
  {value: 'Suriname'},
  {value: 'Svalbard and Jan Mayen'},
  {value: 'Swaziland'},
  {value: 'Sweden'},
  {value: 'Switzerland'},
  {value: 'Syria'},
  {value: 'Taiwan'},
  {value: 'Tajikistan'},
  {value: 'Tanzania'},
  {value: 'Thailand'},
  {value: 'Timor-Leste'},
  {value: 'Togo'},
  {value: 'Tonga'},
  {value: 'Trinidad & Tobago'},
  {value: 'Tunisia'},
  {value: 'Turkey'},
  {value: 'Turkmenistan'},
  {value: 'Turks & Caicos Islands'},
  {value: 'Tuvalu'},
  {value: 'Uganda'},
  {value: 'Ukraine'},
  {value: 'United Arab Emirates'},
  {value: 'United Kingdom'},
  {value: 'United States Minor Outlying Islands'},
  {value: 'Uruguay'},
  {value: 'Uzbekistan'},
  {value: 'Vanuatu'},
  {value: 'Vatican City'},
  {value: 'Venezuela'},
  {value: 'Vietnam'},
  {value: 'Virgin Islands, U.S.'},
  {value: 'Wallis and Futuna'},
  {value: 'Western Sahara'},
  {value: 'Yemen'},
  {value: 'Zambia'},
  {value: 'Zimbabwe'},
]

export const usStates = [
  {value: 'AK'},
  {value: 'AL'},
  {value: 'AR'},
  {value: 'AS'},
  {value: 'AZ'},
  {value: 'CA'},
  {value: 'CO'},
  {value: 'CT'},
  {value: 'DC'},
  {value: 'DE'},
  {value: 'FL'},
  {value: 'FM'},
  {value: 'GA'},
  {value: 'GU'},
  {value: 'HI'},
  {value: 'IA'},
  {value: 'ID'},
  {value: 'IL'},
  {value: 'IN'},
  {value: 'KS'},
  {value: 'KY'},
  {value: 'LA'},
  {value: 'MA'},
  {value: 'MD'},
  {value: 'ME'},
  {value: 'MH'},
  {value: 'MI'},
  {value: 'MN'},
  {value: 'MO'},
  {value: 'MP'},
  {value: 'MS'},
  {value: 'MT'},
  {value: 'NC'},
  {value: 'ND'},
  {value: 'NE'},
  {value: 'NH'},
  {value: 'NJ'},
  {value: 'NM'},
  {value: 'NV'},
  {value: 'NY'},
  {value: 'OH'},
  {value: 'OK'},
  {value: 'OR'},
  {value: 'PA'},
  {value: 'PR'},
  {value: 'PW'},
  {value: 'RI'},
  {value: 'SC'},
  {value: 'SD'},
  {value: 'TN'},
  {value: 'TX'},
  {value: 'UT'},
  {value: 'VA'},
  {value: 'VI'},
  {value: 'VT'},
  {value: 'WA'},
  {value: 'WI'},
  {value: 'WV'},
  {value: 'WY'},
  {value: 'AA'},
  {value: 'AE'},
  {value: 'AP'},
]

export const canStates = [
  {value: 'AB'},
  {value: 'BC'},
  {value: 'MB'},
  {value: 'NB'},
  {value: 'NL'},
  {value: 'NS'},
  {value: 'NT'},
  {value: 'NU'},
  {value: 'ON'},
  {value: 'PE'},
  {value: 'QC'},
  {value: 'SK'},
  {value: 'YT'},
]

export const ausStates = [
  {value: 'ACT'},
  {value: 'NSW'},
  {value: 'NT'},
  {value: 'QLD'},
  {value: 'SA'},
  {value: 'TAS'},
  {value: 'VIC'},
  {value: 'WA'},
]

export const chnStates = [
  {value: 'Anhui'},
  {value: 'Beijing'},
  {value: 'Chongqing'},
  {value: 'Fujian'},
  {value: 'Gansu'},
  {value: 'Guangdong'},
  {value: 'Guangxi'},
  {value: 'Guizhou'},
  {value: 'Hainan'},
  {value: 'Hebei'},
  {value: 'Heilongjiang'},
  {value: 'Henan'},
  {value: 'Hubei'},
  {value: 'Hunan'},
  {value: 'Inner Mongolia'},
  {value: 'Jiangsu'},
  {value: 'Jiangxi'},
  {value: 'Jilin'},
  {value: 'Liaoning'},
  {value: 'Ningxia'},
  {value: 'Qinghai'},
  {value: 'Shaanxi'},
  {value: 'Shandong'},
  {value: 'Shanghai'},
  {value: 'Shanxi'},
  {value: 'Sichuan'},
  {value: 'Tianjin'},
  {value: 'Tibet'},
  {value: 'Xinjiang'},
  {value: 'Yunnan'},
  {value: 'Zhejiang'},
]

export const countriesWithStates = ['United States', 'Canada', 'Australia', 'China']

export function companyStateList(companyCountry: string): {
  value: string
}[] {
  switch (companyCountry) {
    case 'Canada': {
      return canStates
    }
    case 'China': {
      return chnStates
    }
    case 'Australia': {
      return ausStates
    }
    default: {
      return usStates
    }
  }
}
