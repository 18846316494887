import {useEffect} from 'react'
import {useNavigate, Link, useLocation} from 'react-router-dom'
import {css} from '@emotion/react'

import SignupPage from './SignupPage'

const pageCss = css`
  section {
    width: 392px;
  }
`

const formDescriptionCss = css`
  font-size: 16px;
  margin: 26px 0;
  text-align: left;
  line-height: 20px;
`

const smallCss = css`
  font-size: 14px;
  text-align: left;
  margin: 26px 0 0;
  line-height: 18px;

  a {
    font-weight: bold;
  }
`

export default function Verify(): JSX.Element {
  const navigate = useNavigate()
  const {search} = useLocation()
  const nextURL = `/welcome${search}`

  useEffect(() => {
    const timeoutId = window.setTimeout(() => navigate(nextURL), 5000)
    return () => {
      window.clearTimeout(timeoutId)
    }
  }, [navigate, nextURL])

  return (
    <SignupPage
      pageTitle="Email confirmed | Kensho"
      title="Thank you for confirming your email."
      css={pageCss}
    >
      <p css={formDescriptionCss}>Please wait while we redirect you.</p>
      <p css={smallCss}>
        If you&apos;re not redirected automatically, please click <Link to={nextURL}>here</Link>.
      </p>
    </SignupPage>
  )
}
