import {useReducer, useCallback, useMemo} from 'react'

import assertNever from '../utils/assertNever'
import fetchMe from '../api/fetchMe'
import logout from '../api/logout'

import UserContext, {User, UserContextInterface} from './UserContext'

type UserAction = {type: 'logout'} | {type: 'updateUser'; user: User} | {type: 'loading'}

interface UserState {
  user?: User
  _state: UserContextInterface['_state']
}

function userReducer(
  state: UserState,
  action: UserAction,
): {user?: User; _state: UserContextInterface['_state']} {
  switch (action.type) {
    case 'loading':
      return {_state: 'loading'}
    case 'updateUser':
      return {_state: 'known', user: action.user}
    case 'logout':
      return {_state: 'known'}
    default:
      return assertNever(action, 'Unknown action type')
  }
  return state
}

function getUser(
  dispatch: (action: UserAction) => void,
  isCurrent: {value: boolean} = {value: true},
): void {
  fetchMe()
    .then((user) => {
      if (isCurrent.value) dispatch({type: 'updateUser', user})
    })
    .catch(() => {
      if (isCurrent.value) dispatch({type: 'logout'})
    })
}

function triggerUpstreamLogout(dispatch: (action: UserAction) => void): void {
  // user should already be unset, but reset the state on an error just in case
  logout().catch((error) => {
    // eslint-disable-next-line no-console
    console.error(`Error logging out ${error}`)
    dispatch({type: 'logout'})
  })
}

export default function UserProvider({children}: {children: React.ReactNode}): JSX.Element {
  const [{user, _state}, dispatch] = useReducer(userReducer, {_state: 'unknown'})

  const logoutCallback = useCallback(() => {
    dispatch({type: 'logout'})
    triggerUpstreamLogout(dispatch)
  }, [])

  const userContextValue = useMemo<UserContextInterface>(
    () => ({
      user,
      _state,
      getUser: () => getUser(dispatch),
      logout: logoutCallback,
    }),
    [_state, logoutCallback, user],
  )

  return <UserContext.Provider value={userContextValue}>{children}</UserContext.Provider>
}
