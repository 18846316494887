import {css} from '@emotion/react'

import ProductCardInfoProps from '../constants/productCardInformation'

const iconContainerCss = css`
  display: flex;
  align-items: flex-start;
`

const smIconCss = css`
  height: 40px;
`

interface ProductIconProps {
  product: ProductCardInfoProps
  className?: string
  size?: 'small' | 'default'
}

export default function ProductIcon({
  product,
  className = '',
  size = 'default',
}: ProductIconProps): JSX.Element {
  const sm = size === 'small'
  return (
    <div className={className} css={iconContainerCss}>
      <img css={sm && smIconCss} src={product.icon} alt={product.productName} />
    </div>
  )
}
