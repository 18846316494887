import {css} from '@emotion/react'
import {useContext, useEffect} from 'react'

import UserContext from '../providers/UserContext'
import {NAV_ITEMS} from '../routes'

import Header from './Header'
import LoginRequired from './LoginRequired'

const pageCss = css`
  max-width: 800px;
  margin: 0 auto;
`

interface PageProps {
  children: React.ReactNode
  useDefaultHeader?: boolean
  authenticated?: boolean
}

export default function Page({
  children,
  useDefaultHeader = true,
  authenticated = false,
}: PageProps): JSX.Element {
  const {getUser, _state} = useContext(UserContext)

  useEffect(() => {
    const isCurrent = {value: true}
    if (authenticated && _state === 'unknown') getUser(isCurrent)

    return () => {
      isCurrent.value = false
    }
  }, [authenticated, _state, getUser])

  const Content = (
    <>
      {useDefaultHeader && <Header navItems={NAV_ITEMS} />}
      <main css={pageCss}>{children}</main>
    </>
  )

  return authenticated ? <LoginRequired>{Content}</LoginRequired> : Content
}
