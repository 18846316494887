import {useCallback} from 'react'
import {AsyncValue, useFetch} from '@kensho/tacklebox'
import {snakeCase} from 'lodash'

import camelCaseDeep from '../utils/camelCaseDeep'

function toSearchParams<T extends object>(params: T): string {
  const stringifiedParams = Object.entries(params).reduce(
    (prevParams: Record<string, string>, [key, value]) => {
      const nextParams = {...prevParams}
      if (value) nextParams[snakeCase(key)] = `${value}`
      return nextParams
    },
    {},
  )

  return new URLSearchParams(stringifiedParams).toString()
}

export default function useBaseFetch<ParamsShape, ResponseShape>(
  url: string,
  params?: ParamsShape,
  init?: RequestInit,
): AsyncValue<ResponseShape> {
  const baseParse = useCallback((response: Response): Promise<ResponseShape> => {
    if (!response.ok) throw new Error()
    return response.json().then(camelCaseDeep)
  }, [])

  const searchParams = params ? toSearchParams(params) : null
  const parameterizedUrl = `${url}${searchParams ? `?${searchParams}` : ''}`
  return useFetch(parameterizedUrl, init, baseParse)
}
