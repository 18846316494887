import {useContext, useReducer, useEffect, useMemo} from 'react'

import fetchProductMetadata, {ProductMetadataResponse} from '../../api/fetchProductMetadata'
import assertNever from '../../utils/assertNever'
import UserContext from '../UserContext'

import ProductMetadataContext from './Context'
import {ProductMetadataState} from './types'

type ProductMetadataRequestAction =
  | {type: 'loadProductMetadata'}
  | {type: 'update'; productMetadata: ProductMetadataResponse[]}
  | {type: 'error'; error: string}

function productMetadataReducer(
  _state: ProductMetadataState,
  action: ProductMetadataRequestAction,
): ProductMetadataState {
  switch (action.type) {
    case 'loadProductMetadata':
      return {status: 'loading'}
    case 'update': {
      if (!action.productMetadata.length) {
        return {status: 'error', error: 'No products found for this user.'}
      }
      return {status: 'success', productMetadata: action.productMetadata}
    }
    case 'error':
      return {status: 'error', error: action.error}
    default:
      return assertNever(action, 'Unknown action type')
  }
  return {status: 'error', error: 'Unknown state'}
}

function loadProductMetadata(
  isCurrent: {value: boolean},
  dispatch: (action: ProductMetadataRequestAction) => void,
): void {
  dispatch({type: 'loadProductMetadata'})
  fetchProductMetadata()
    .then((productMetadata) => {
      if (isCurrent.value) dispatch({type: 'update', productMetadata})
    })
    .catch((error) => {
      if (isCurrent.value) dispatch({type: 'error', error})
    })
}

export default function PricingProvider({children}: {children: React.ReactNode}): JSX.Element {
  const [state, dispatch] = useReducer(productMetadataReducer, {
    status: 'uninitiated',
  })

  const {user} = useContext(UserContext)

  useEffect(() => {
    if (!user?.email) return undefined

    const isCurrent = {value: true}
    loadProductMetadata(isCurrent, dispatch)

    return () => {
      isCurrent.value = false
    }
  }, [user?.email])

  const productMetadataContext = useMemo(
    () => ({
      state,
      getProductMetadata: (isCurrent: {value: boolean}) => loadProductMetadata(isCurrent, dispatch),
    }),
    [state],
  )

  return (
    <ProductMetadataContext.Provider value={productMetadataContext}>
      {children}
    </ProductMetadataContext.Provider>
  )
}
