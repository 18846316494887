import {LoggerProvider, transportSentry, transportConsole} from '@kensho/lumberjack'

const transports =
  process.env.NODE_ENV === 'development'
    ? [transportConsole()]
    : [transportSentry({levels: ['warning', 'error']})]

interface AppLoggerProviderProps {
  children: React.ReactNode
}

export default function AppLoggerProvider(props: AppLoggerProviderProps): JSX.Element {
  const {children} = props
  return <LoggerProvider transports={transports}>{children}</LoggerProvider>
}
