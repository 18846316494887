import {useEffect, useState} from 'react'
import {createPortal} from 'react-dom'
import {css, SerializedStyles} from '@emotion/react'
import {Manager, Reference, Popper, PopperProps} from 'react-popper'
import {Placement} from '@popperjs/core'

import PopoverDynamicContent from './PopoverDynamicContent'

interface PopoverProps {
  isOpen: boolean
  target: React.ReactNode
  children: React.ReactNode
  popperPlacement?: PopperProps<Placement>['placement']
  popoverClassName?: string | SerializedStyles
}

const popoverSurfaceCss = css`
  background-color: white;
  border-radius: 3px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 5px;
  max-height: 100%;
  z-index: 600;
`

const removePortalNode = (node: Node): void => {
  try {
    if (node) document.body.removeChild(node)
  } catch (e) {
    // ignore
  }
}

export default function Popover(props: PopoverProps): JSX.Element {
  const {isOpen, children, popperPlacement = 'bottom-end', target, popoverClassName} = props

  const [portalContainerElement, setPortalContainerElement] = useState<HTMLDivElement | null>(null)

  useEffect(() => {
    const containerElement = document.createElement('div')
    containerElement.className = 'popover-container'
    document.body.appendChild(containerElement)
    setPortalContainerElement(containerElement)

    return () => {
      removePortalNode(containerElement)
      setPortalContainerElement(null)
    }
  }, [])

  return (
    <Manager>
      <Reference>{({ref}) => <div ref={ref}>{target}</div>}</Reference>
      {isOpen && portalContainerElement && (
        <Popper placement={popperPlacement}>
          {({ref, style, placement, update}) =>
            createPortal(
              <div
                ref={ref}
                data-placement={placement}
                style={style}
                css={[popoverSurfaceCss, popoverClassName]}
              >
                <PopoverDynamicContent onResize={update}>{children}</PopoverDynamicContent>
              </div>,
              portalContainerElement,
            )
          }
        </Popper>
      )}
    </Manager>
  )
}
