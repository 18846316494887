import {lazy, StrictMode, Suspense} from 'react'
import {BrowserRouter, Routes, Route, Navigate, Outlet} from 'react-router-dom'
import 'normalize.css'

import Page from './components/Page'
import CookieConsentPopup from './components/CookieBanner/CookieConsentPopup'
import SignupEmailPage from './pages/Signup/Email'
import SignupPasswordPage from './pages/Signup/Password'
import SignupVerifyPage from './pages/Signup/Verify'
import SignupVerifyConfirmPage from './pages/Signup/VerifyConfirm'
import UserProvider from './providers/UserProvider'
import PricingProvider from './providers/PricingProvider'
import ProductMetadataProvider from './providers/ProductMetadata/Provider'
import GlobalStyles from './GlobalStyles'
import AppLoggerProvider from './AppLoggerProvider'
import ErrorBoundary from './ErrorBoundary'
import LoadingContainer from './components/LoadingContainer'
import {ROUTES} from './routes'
import Solutions from './pages/Solutions'
import ProductParamRedirect from './components/ProductParamRedirect'
import Welcome from './pages/Welcome'
import {IsMountedProvider} from './hooks/useIsMounted'
import SignUpModeContext from './providers/SignUpModeContext'

const Account = lazy(() => import(/* webpackPrefetch: true */ './pages/Account'))
const Billing = lazy(() => import(/* webpackPrefetch: true */ './pages/Billing'))
const Services = lazy(() => import(/* webpackPrefetch: true */ './pages/Services'))

export default function App(): JSX.Element {
  return (
    <StrictMode>
      <BrowserRouter>
        <AppLoggerProvider>
          <ErrorBoundary>
            <IsMountedProvider>
              <UserProvider>
                <ProductMetadataProvider>
                  <CookieConsentPopup />
                  <ProductParamRedirect />
                  <GlobalStyles />
                  <Routes>
                    <Route path={ROUTES.FREE_TRIAL_SIGNUP} element={<SignupEmailPage />} />
                    <Route path={ROUTES.FREE_TRIAL_PASSWORD} element={<SignupPasswordPage />} />
                    <Route path={ROUTES.FREE_TRIAL_VERIFY} element={<SignupVerifyPage />} />
                    <Route path={ROUTES.FREE_TRIAL_CONFIRM} element={<SignupVerifyConfirmPage />} />
                    <Route
                      element={
                        <SignUpModeContext.Provider value="generic">
                          <Outlet />
                        </SignUpModeContext.Provider>
                      }
                    >
                      <Route path={ROUTES.GENERIC_SIGNUP} element={<SignupEmailPage />} />
                      <Route path={ROUTES.GENERIC_PASSWORD} element={<SignupPasswordPage />} />
                      <Route path={ROUTES.GENERIC_VERIFY} element={<SignupVerifyPage />} />
                      <Route path={ROUTES.GENERIC_CONFIRM} element={<SignupVerifyConfirmPage />} />
                    </Route>
                    <Route path={ROUTES.WELCOME} element={<Welcome />} />
                    <Route path={ROUTES.SOLUTIONS} element={<Solutions />} />
                    <Route
                      path={ROUTES.ACCOUNT}
                      element={
                        <Page authenticated>
                          <Suspense fallback={<LoadingContainer />}>
                            <Account />
                          </Suspense>
                        </Page>
                      }
                    />
                    <Route
                      path={ROUTES.SERVICES}
                      element={
                        <Page authenticated>
                          <PricingProvider subscribedPlansOnly>
                            <Suspense fallback={<LoadingContainer />}>
                              <Services />
                            </Suspense>
                          </PricingProvider>
                        </Page>
                      }
                    />
                    <Route
                      path={ROUTES.BILLING}
                      element={
                        <Page authenticated>
                          <PricingProvider subscribedPlansOnly>
                            <Suspense fallback={<LoadingContainer />}>
                              <Billing />
                            </Suspense>
                          </PricingProvider>
                        </Page>
                      }
                    />
                    {/* TODO: replace redirect fallback with 404 page */}
                    <Route path="*" element={<Navigate to={ROUTES.ACCOUNT} replace />} />
                  </Routes>
                </ProductMetadataProvider>
              </UserProvider>
            </IsMountedProvider>
          </ErrorBoundary>
        </AppLoggerProvider>
      </BrowserRouter>
    </StrictMode>
  )
}
