import {css} from '@emotion/react'

type HeadingProps = React.HTMLAttributes<HTMLHeadingElement>

const h1Css = css`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
`

const h2Css = css`
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
`

const h3Css = css`
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
`

export function H1({children, ...props}: HeadingProps): JSX.Element {
  return (
    <h1 {...props} css={h1Css}>
      {children}
    </h1>
  )
}

export function H2({children, ...props}: HeadingProps): JSX.Element {
  return (
    <h2 {...props} css={h2Css}>
      {children}
    </h2>
  )
}

export function H3({children, ...props}: HeadingProps): JSX.Element {
  return (
    <h3 {...props} css={h3Css}>
      {children}
    </h3>
  )
}
