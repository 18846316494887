import {css} from '@emotion/react'
import type {LinkProps} from 'react-router-dom'

import Link from './Link'
import {ButtonVariant, getButtonStyles} from './getButtonStyles'

const buttonLinkCss = css`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 10px 40px;

  font-size: 18px;
  line-height: 21.92px;
  font-weight: 400;

  cursor: pointer;
  transition: all 300ms ease;
  border: 1px solid #007694;
  color: #50afc6;

  &:hover {
    opacity: 1;
  }
`

interface ButtonLinkProps extends LinkProps {
  variant: ButtonVariant
  mode?: 'dark' | 'light'
}

export default function ButtonLink({
  className,
  children,
  to,
  variant,
  mode = 'light',
  ...rest
}: ButtonLinkProps): JSX.Element {
  return (
    <Link
      css={[buttonLinkCss, getButtonStyles(variant, mode)]}
      className={className}
      to={to}
      {...rest}
    >
      {children}
    </Link>
  )
}
