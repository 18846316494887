import {useReducer, useCallback, useState, useEffect, useRef, useMemo, useContext} from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {css} from '@emotion/react'
import {IconChevronRightLarge, IconEdit} from '@kensho/icons'
import {Button, Checkbox} from '@kensho/ui'

import isEmailValid from '../../utils/isEmailValid'
import postData from '../../utils/postData'
import LabeledInput from '../../components/LabeledInput'
import SpinnerIcon from '../../components/SpinnerIcon'
import getEmailFromURLParams from '../../utils/getEmailFromURLParams'
import Typeahead from '../../components/Typeahead'
import {countriesWithStates, countriesList, companyStateList} from '../../constants/countriesList'
import {Item} from '../../components/TypeaheadFiltering'
import SignUpModeContext from '../../providers/SignUpModeContext'

import PasswordInput from './PasswordInput'
import SignInButton from './SignInButton'
import SignupPage from './SignupPage'
import {VALIDATION_ERROR, getErrorMessage} from './constants'
import SubmissionErrorMessage from './SubmissionErrorMessage'

const buttonsSectionCss = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  margin-top: 24px;
`

const editIconCss = css`
  flex: 0 0 auto;
  margin-left: 8px;
`

const topButtonCss = css`
  position: relative;
  margin-top: 8px;

  a {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const termsCheckboxCss = css`
  margin-top: 24px;

  span:first-of-type::before {
    -moz-margin-start: 4px;
  }
`

const marketingCheckboxCss = css`
  margin-top: 12px;

  span:first-of-type::before {
    top: 11px;
    -moz-margin-start: 4px;
  }
`

const checkboxCss = css`
  span:first-of-type {
    flex: 0 0 16px;
  }

  span {
    font-size: 11px;
    line-height: 15px;
  }
`

const errorMessageCss = css`
  color: #db374d;
`

const hiddenInputCss = css`
  visibility: hidden;
`

const inputContainerOverrideCss = css`
  height: 58px;
`

interface FormValidationState {
  firstName: {value: string; validationErrors: string[]}
  lastName: {value: string; validationErrors: string[]}
  companyName: {value: string; validationErrors: string[]}
  companyCity: {value: string; validationErrors: string[]}
  companyCountry: {value: string; validationErrors: string[]}
  companyState: {value: string; validationErrors: string[]}
  password: {value: string; validationErrors: string[]}
  agreeToTerms: {value: boolean; validationErrors: string[]}
  marketingConsent: {value: boolean; validationErrors: string[]}
}

interface FormState extends FormValidationState {
  status: 'submitted' | 'unsubmitted'
}

interface FormSubmitAction {
  action: 'submit'
}

interface FormChangeAction {
  action: 'change'
  field: string
  value: string
}

interface FormBlurAction {
  action: 'blur'
  field: string
}

interface FormValidateAction {
  action: 'validate'
  result: FormValidationState
}
type FormAction = FormSubmitAction | FormChangeAction | FormBlurAction | FormValidateAction

const initialFormState: FormState = {
  status: 'unsubmitted',
  firstName: {value: '', validationErrors: []},
  lastName: {value: '', validationErrors: []},
  companyName: {value: '', validationErrors: []},
  companyCity: {value: '', validationErrors: []},
  companyCountry: {value: '', validationErrors: []},
  companyState: {value: '', validationErrors: []},
  password: {value: '', validationErrors: []},
  agreeToTerms: {value: false, validationErrors: []},
  marketingConsent: {value: false, validationErrors: []},
}

enum APIError {
  DEFAULT_API_ERROR = 'DEFAULT_API_ERROR',
  OKTA_FORBIDDEN_ERROR = 'OKTA_FORBIDDEN_ERROR',
}

// Valid symbols are non-whitespace, non-number, non-letter ASCII characters
const VALID_SYMBOL_RANGES = [
  [33, 47],
  [58, 64],
  [91, 96],
  [123, 126],
]

function isSymbol(c: string): boolean {
  if (c.length > 1) return false
  const code = c.charCodeAt(0)
  return VALID_SYMBOL_RANGES.some(([start, end]) => code >= start && code <= end)
}

function validatePassword(value: string): string[] {
  const validationErrors = []
  const numUpper = (value.match(/[A-Z]/g) || []).length
  const numLower = (value.match(/[a-z]/g) || []).length
  const numNumber = (value.match(/[0-9]/g) || []).length
  const hasSymbol = [...value].some(isSymbol)
  if (value.length === 0) validationErrors.push(VALIDATION_ERROR.EMPTY)
  if (value.length < 10) validationErrors.push(VALIDATION_ERROR.TOO_SHORT)
  if (value.length > 72) validationErrors.push(VALIDATION_ERROR.TOO_LONG)
  if (numLower === 0) validationErrors.push(VALIDATION_ERROR.LOWERCASE)
  if (numUpper === 0) validationErrors.push(VALIDATION_ERROR.UPPERCASE)
  if (numNumber === 0) validationErrors.push(VALIDATION_ERROR.NUMBER)
  if (!hasSymbol) validationErrors.push(VALIDATION_ERROR.SYMBOL)
  if (value.length - numUpper - numLower === 0) validationErrors.push(VALIDATION_ERROR.EMPTY)
  return validationErrors
}

function validateTextField(value: string): string[] {
  const nextValue = value.trim()
  if (nextValue.length === 0) {
    return [VALIDATION_ERROR.EMPTY]
  }
  return []
}

function validateForm(state: FormState): FormValidationState {
  return {
    firstName: {
      value: state.firstName.value.trim(),
      validationErrors: validateTextField(state.firstName.value),
    },
    lastName: {
      value: state.lastName.value.trim(),
      validationErrors: validateTextField(state.lastName.value),
    },
    companyName: {
      value: state.companyName.value.trim(),
      validationErrors: validateTextField(state.companyName.value),
    },
    companyCity: {
      value: state.companyCity.value.trim(),
      validationErrors: validateTextField(state.companyCity.value),
    },
    companyCountry: {
      value: state.companyCountry.value.trim(),
      validationErrors: validateTextField(state.companyCountry.value),
    },
    companyState: {
      value: state.companyState.value.trim(),
      validationErrors: [], // State can be optional
    },
    password: {
      value: state.password.value,
      validationErrors: validatePassword(state.password.value),
    },
    agreeToTerms: {
      value: state.agreeToTerms.value,
      validationErrors: !state.agreeToTerms.value ? [getErrorMessage(VALIDATION_ERROR.EMPTY)] : [],
    },
    marketingConsent: {
      value: state.marketingConsent.value,
      validationErrors: [],
    },
  }
}

function reducer(state: FormState, action: FormAction): FormState {
  switch (action.action) {
    case 'validate': {
      return {...state, ...action.result, status: 'unsubmitted'}
    }
    case 'submit': {
      return {...state, status: 'submitted'}
    }
    case 'change': {
      const {field, value} = action
      switch (field) {
        case 'password': {
          // special password validation
          return {...state, [field]: {value, validationErrors: validatePassword(value)}}
        }
        case 'agreeToTerms': {
          return {...state, [field]: {value: !state.agreeToTerms.value, validationErrors: []}}
        }
        case 'marketingConsent': {
          return {...state, [field]: {value: !state.marketingConsent.value, validationErrors: []}}
        }
        default:
          return {...state, [field]: {value, validationErrors: validateTextField(value)}}
      }
    }
    case 'blur': {
      const {field} = action
      switch (field) {
        case 'password': {
          // special password validation
          const {value} = state[field]
          return {...state, [field]: {value, validationErrors: validatePassword(value)}}
        }
        default: {
          return state
        }
      }
    }
    default:
      break
  }
  return state
}

export default function Password(): JSX.Element {
  const location = useLocation()
  const navigate = useNavigate()
  const signUpMode = useContext(SignUpModeContext)
  const [email] = useState<string | null>(getEmailFromURLParams(location))
  const [apiError, setApiError] = useState<APIError | null>(null)
  const [formState, dispatch] = useReducer(reducer, initialFormState)
  const {extraParamsString, searchParams} = useMemo(() => {
    const extraURLParams = new URLSearchParams(location.search)
    extraURLParams.delete('error')
    extraURLParams.delete('email')
    return {extraParamsString: extraURLParams.toString(), searchParams: extraURLParams}
  }, [location.search])

  const baseUrl = signUpMode === 'generic' ? '/sign-up' : '/free-trial'

  const product = searchParams.get('product')
  const firstInputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (!email) {
      navigate(
        `${baseUrl}${product ? `/${product}` : ''}?error=no-email${
          extraParamsString ? `&${extraParamsString}` : ''
        }`,
      )
      return
    }
    if (!isEmailValid(email)) {
      navigate(
        `${baseUrl}${product ? `/${product}` : ''}?error=email-invalid&email=${encodeURIComponent(
          email,
        )}${extraParamsString ? `&${extraParamsString}` : ''}`,
      )
    }
  }, [navigate, email, extraParamsString, product, baseUrl])

  useEffect(() => {
    firstInputRef.current?.focus()
  }, [firstInputRef])

  const onChange = useCallback(
    (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({
        action: 'change',
        field,
        value: e.target.value,
      })
    },
    [],
  )

  const onTypeaheadChange = useCallback(
    (field: string) => (typeaheadItem: Item | null) => {
      if (typeaheadItem == null) {
        return
      }
      dispatch({
        action: 'change',
        field,
        value: typeaheadItem.value,
      })
    },
    [],
  )

  const onCheckboxChange = useCallback(
    (field: 'marketingConsent' | 'agreeToTerms') => () => {
      dispatch({
        action: 'change',
        field,
        value: 'dummy', // pass a dummy value because we just toggle
      })
    },
    [],
  )

  const onBlur = useCallback(
    (field: string) => () => {
      dispatch({action: 'blur', field})
    },
    [],
  )

  const onSubmit = useCallback(() => {
    const validationResult = validateForm(formState)
    if (
      validationResult.firstName.validationErrors.length +
        validationResult.lastName.validationErrors.length +
        validationResult.companyName.validationErrors.length +
        validationResult.companyCity.validationErrors.length +
        validationResult.companyCountry.validationErrors.length +
        validationResult.companyState.validationErrors.length +
        validationResult.password.validationErrors.length +
        validationResult.agreeToTerms.validationErrors.length >
      0
    ) {
      dispatch({
        action: 'validate',
        result: validationResult,
      })
    } else {
      if (!email) return
      const firstName = formState.firstName.value
      const lastName = formState.lastName.value
      postData<unknown, {error_message: string}>(`/users/v1/create_user/?${extraParamsString}`, {
        first_name: firstName,
        last_name: lastName,
        display_name: firstName,
        primary_email: email,
        company_name: formState.companyName.value,
        company_city: formState.companyCity.value,
        company_country: formState.companyCountry.value,
        company_state: formState.companyState.value,
        password: formState.password.value,
        marketing_consent: formState.marketingConsent.value,
      })
        .then((result) => {
          const errorMessage = 'error' in result ? result.error.error_message : null
          if (result.status < 400) {
            navigate(`${baseUrl}/verify?email=${encodeURIComponent(email)}`)
          } else if (
            result.status === 403 &&
            errorMessage?.includes('Cannot manage users from the domain in the profile obj')
          ) {
            setApiError(APIError.OKTA_FORBIDDEN_ERROR)
          } else {
            setApiError(APIError.DEFAULT_API_ERROR)
          }
        })
        .catch(() => {
          setApiError(APIError.DEFAULT_API_ERROR)
        })
      dispatch({action: 'submit'})
    }
  }, [email, formState, navigate, baseUrl, extraParamsString])

  const onFormSubmit = useCallback(
    (e: React.ChangeEvent<HTMLFormElement>) => {
      e.preventDefault()
      onSubmit()
    },
    [onSubmit],
  )

  return (
    <SignupPage
      pageTitle="Create Password | Kensho"
      title={signUpMode === 'generic' ? 'Create an Account' : 'Start a Free Trial'}
    >
      <Button css={topButtonCss} fill minimal>
        <Link
          aria-label="Go back"
          to={`${email ? `${baseUrl}?email=${encodeURIComponent(email)}` : `${baseUrl}`}${
            extraParamsString ? `&${extraParamsString}` : ''
          }`}
        >
          {email} <IconEdit css={editIconCss} />
        </Link>
      </Button>
      <form onSubmit={onFormSubmit}>
        <LabeledInput
          autofocus
          id="firstName"
          label="First Name"
          value={formState.firstName.value}
          onBlur={onBlur('firstName')}
          onChange={onChange('firstName')}
          error={getErrorMessage(formState.firstName.validationErrors[0])}
          containerOverrideCss={inputContainerOverrideCss}
        />
        <LabeledInput
          id="lastName"
          label="Last Name"
          value={formState.lastName.value}
          onBlur={onBlur('lastName')}
          onChange={onChange('lastName')}
          error={getErrorMessage(formState.lastName.validationErrors[0])}
          containerOverrideCss={inputContainerOverrideCss}
        />
        <LabeledInput
          id="companyName"
          label="Company Name"
          value={formState.companyName.value}
          onChange={onChange('companyName')}
          onBlur={onChange('companyName')}
          error={getErrorMessage(formState.companyName.validationErrors[0])}
          containerOverrideCss={inputContainerOverrideCss}
        />
        <LabeledInput
          id="companyCity"
          label="Company City"
          value={formState.companyCity.value}
          onChange={onChange('companyCity')}
          onBlur={onChange('companyCity')}
          error={getErrorMessage(formState.companyCity.validationErrors[0])}
          containerOverrideCss={inputContainerOverrideCss}
        />
        <Typeahead
          id="companyCountry"
          label="Company Country"
          selectedValue={formState.companyCountry.value}
          onChange={onTypeaheadChange('companyCountry')}
          error={getErrorMessage(formState.companyCountry.validationErrors[0])}
          items={countriesList}
        />
        {countriesWithStates.includes(formState.companyCountry.value) ? (
          <Typeahead
            id="companyState"
            label="Company State"
            selectedValue={formState.companyState.value}
            onChange={onTypeaheadChange('companyState')}
            error={getErrorMessage(formState.companyState.validationErrors[0])}
            items={companyStateList(formState.companyCountry.value)}
          />
        ) : (
          <LabeledInput
            id="companyState"
            label="Company State"
            value={formState.companyState.value}
            onChange={onChange('companyState')}
            onBlur={onChange('companyState')}
            containerOverrideCss={inputContainerOverrideCss}
          />
        )}
        <PasswordInput
          id="password"
          label="Password"
          value={formState.password.value}
          onChange={onChange('password')}
          onBlur={onBlur('password')}
          errors={formState.password.validationErrors}
        />
        <Checkbox
          className="privacy-checkbox"
          css={[checkboxCss, termsCheckboxCss]}
          checked={formState.agreeToTerms.value}
          onChange={onCheckboxChange('agreeToTerms')}
          label={
            <span
              data-testid="privacy-error-message"
              css={formState.agreeToTerms.validationErrors.length > 0 && errorMessageCss}
            >
              I agree to Kensho&apos;s <a href="https://kensho.com/privacy"> Privacy Policy </a>and
              <a href="https://kensho.com/terms-of-service"> Terms of Service</a>.
            </span>
          }
        />
        <Checkbox
          className="marketing-checkbox"
          css={[checkboxCss, marketingCheckboxCss]}
          checked={formState.marketingConsent.value}
          onChange={onCheckboxChange('marketingConsent')}
          label={
            <>I&apos;d like to receive product information, updates, and promotions from Kensho.</>
          }
        />
        <input type="submit" css={hiddenInputCss} />
      </form>

      <section css={buttonsSectionCss}>
        <SignInButton />
        {formState.status === 'submitted' && !apiError ? (
          <SpinnerIcon />
        ) : (
          <Button
            size="large"
            intent="primary"
            disabled={!!apiError}
            onClick={onSubmit}
            rightIcon={IconChevronRightLarge}
          >
            {signUpMode === 'generic' ? 'Create Your Account' : 'Start Your Free Trial'}
          </Button>
        )}
      </section>

      {apiError && (
        <SubmissionErrorMessage
          product={product}
          defaultAPIError={apiError === APIError.DEFAULT_API_ERROR}
          email={email}
        />
      )}
    </SignupPage>
  )
}
