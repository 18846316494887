import {css, SerializedStyles} from '@emotion/react'

import {STANDARD_BREAKPOINTS} from '../styleConstants'

export default function getBackgroundCss(med: string, large: string): SerializedStyles {
  return css`
    background-image: url(${large});
    filter: none;

    @media (max-width: ${STANDARD_BREAKPOINTS.DESKTOP}) {
      background-image: url(${med});
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      opacity: 1;
    }
  `
}
