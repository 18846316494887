import {css} from '@emotion/react'

import SpinnerIcon from './SpinnerIcon'

const loadingContainerCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
`

export default function LoadingContainer(): JSX.Element {
  return (
    <div css={loadingContainerCss}>
      <SpinnerIcon />
    </div>
  )
}
