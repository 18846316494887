import {useContext, useEffect} from 'react'

import UserContext from '../providers/UserContext'

interface LoginRequiredProps {
  children: JSX.Element
}

export default function LoginRequired(props: LoginRequiredProps): JSX.Element | null {
  const {children} = props
  const {user, _state: userState} = useContext(UserContext)

  useEffect(() => {
    if (userState !== 'known') return
    if (!user?.email) window.location.href = '/users/login'
  }, [userState, user?.email])

  return userState === 'unknown' ? null : children
}
