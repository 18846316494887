import camelCaseDeep from './camelCaseDeep'

/**
 * Fetch a resource, transform the response to JSON, and provide an option to reject after a timeout
 *
 * @param apiEndpoint The URL target for this fetch
 * @param params The options to pass to the fetch request
 * @param timeout The time in ms after which the request should be automatically rejected
 */
export default function fetchResource<T>(
  apiEndpoint: string,
  params?: RequestInit,
  timeout?: number,
): Promise<T> {
  return new Promise((resolve, reject) => {
    fetch(apiEndpoint, params)
      .then((response) => {
        if (response.ok) return response.json()
        throw response
      })
      .then((json) => camelCaseDeep(json) as T)
      .then(resolve, reject)

    if (timeout && timeout > 0) {
      setTimeout(reject, timeout, new Error('Request timed out'))
    }
  })
}
