interface HighlightedSubstringProps {
  match: string | null
  text: string
}

export function HighlightedSubstring({match, text}: HighlightedSubstringProps): JSX.Element {
  if (!match) return <>{text}</>
  const index = text.toLowerCase().indexOf(match)
  const start = text.substring(0, index)
  const middle = text.substring(index, index + match.length)
  const end = text.substring(index + match.length)

  return (
    <span>
      {start}
      <strong>{middle}</strong>
      {end}
    </span>
  )
}
