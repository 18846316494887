import {css} from '@emotion/react'

import {BREAKPOINTS} from '../styleConstants'
import ProductCardInfoProps from '../constants/productCardInformation'

import ButtonLink from './Button/ButtonLink'
import Button from './Button/Button'
import ProductIcon from './ProductIcon'

const compactProductCardCss = css`
  display: flex;
  background: #ffffff;
  padding: 40px;
  max-width: 444px;
  border: 1px solid #e4e5e5;
  flex-direction: column;
  gap: 55px;
  justify-content: space-between;
  @media (max-width: ${BREAKPOINTS.SMALL}) {
    max-width: 100%;
  }
`

const compactProductInformationCss = css`
  display: flex;
  flex-direction: column;
  gap: 55px;
  height: 100%;
  @media (max-width: ${BREAKPOINTS.MEDIUM}) {
    justify-content: space-between;
  }
`

const buttonsContainerCss = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${BREAKPOINTS.MEDIUM}) {
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    gap: 10px;
  }
`

const titleCss = css`
  color: #0b008f;
  font-weight: 700;
  font-size: 16px;
`

const compactProductSubtitle = css`
  font-size: 16px;
`

const buttonCss = css`
  padding: 10px 30px;
  display: flex;
  @media (max-width: ${BREAKPOINTS.MEDIUM}) {
    width: 100%;
  }
`

const externalLinkCss = css`
  display: flex;
  padding: 10px 30px;
  color: #09819f;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const productLaunchCss = css`
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #007694;
  text-decoration: none;
  margin-bottom: 0;
`

interface ProductCardProps {
  product: ProductCardInfoProps
}

export function ButtonsContainer({product}: ProductCardProps): JSX.Element {
  const {productName} = product
  return (
    <div css={buttonsContainerCss}>
      <Button css={buttonCss} variant="secondary" mode="light">
        <a
          href={product.signInLink}
          css={productLaunchCss}
          target="_blank"
          rel="noopener noreferrer"
        >
          Launch {productName}
        </a>
      </Button>
      <ButtonLink css={externalLinkCss} to={product.learnMoreLink} variant="minimal" mode="light">
        Learn More
      </ButtonLink>
    </div>
  )
}

export default function CompactProductCard({product}: ProductCardProps): JSX.Element {
  return (
    <section css={compactProductCardCss}>
      <div css={compactProductInformationCss}>
        <p css={titleCss}>{product.title}</p>
        <ProductIcon product={product} />
        <p css={compactProductSubtitle}>{product.subtitle}</p>
      </div>
      <ButtonsContainer product={product} />
    </section>
  )
}
