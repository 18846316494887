export const ROUTES = {
  ACCOUNT: '/account/details',
  SERVICES: '/account/services',
  BILLING: '/account/billing',
  WELCOME: '/welcome/:product?',
  SOLUTIONS: '/:product?',
  FREE_TRIAL_SIGNUP: '/free-trial/:product?',
  FREE_TRIAL_PASSWORD: '/free-trial/password',
  FREE_TRIAL_VERIFY: '/free-trial/verify',
  FREE_TRIAL_CONFIRM: '/free-trial/confirm',
  GENERIC_SIGNUP: '/sign-up/:product?',
  GENERIC_PASSWORD: '/sign-up/password',
  GENERIC_VERIFY: '/sign-up/verify',
  GENERIC_CONFIRM: '/sign-up/confirm',
}

export const NAV_ITEMS = [
  {title: 'Account Details', url: ROUTES.ACCOUNT},
  {title: 'My Services', url: ROUTES.SERVICES},
  {title: 'Billing', url: ROUTES.BILLING},
]
